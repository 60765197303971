export const productValidationConstants = {
  KEY_USER_GROUP_CODE: 'KEY_USER_GROUP_CODE',
  KEY_CATEGORY_TYPE: 'KEY_CATEGORY_TYPE',
  KEY_CATEGORY_CODE: 'KEY_CATEGORY_CODE',
  KEY_CATEGORY_DESCRIPTION: 'KEY_CATEGORY_DESCRIPTION',
  KEY_CATEGORY_CHANNEL: 'KEY_CATEGORY_CHANNEL',
  KEY_CATEGORY_NAME_TC: 'KEY_CATEGORY_NAME_TC',
  KEY_CATEGORY_NAME_EN: 'KEY_CATEGORY_NAME_EN',
  KEY_CATEGORY_NAME_SC: 'KEY_CATEGORY_NAME_SC',
  KEY_CATEGORY_START_DISPLAY_TIMESTAMP: 'KEY_CATEGORY_START_DISPLAY_TIMESTAMP',
  KEY_CATEGORY_END_DISPLAY_TIMESTAMP: 'KEY_CATEGORY_END_DISPLAY_TIMESTAMP',
  KEY_REDEEM_GROUP_START_AFTER_DAY: 'KEY_REDEEM_GROUP_START_AFTER_DAY',
  KEY_REDEEM_GROUP_END_AFTER_DAY: 'KEY_REDEEM_GROUP_END_AFTER_DAY',
  KEY_CATEGORY_DISPLAY_SEQUENCE: 'KEY_CATEGORY_DISPLAY_SEQUENCE',
  KEY_CATEGORY_IMAGE_LIST: 'KEY_CATEGORY_IMAGE_LIST',
  KEY_CATEGORY_IMAGE_VALI_CODE: 'KEY_CATEGORY_IMAGE_VALI_CODE',
  KEY_CATEGORY_IMAGE_TYPE: 'KEY_CATEGORY_IMAGE_TYPE',
  KEY_CATEGORY_IMAGE_DISPLAY_SEQUENCE: 'KEY_CATEGORY_IMAGE_DISPLAY_SEQUENCE',
  KEY_CATEGORY_IMAGE: 'KEY_CATEGORY_IMAGE',
  KEY_CATEGORY_PRODUCT_MAP_DISPLAY_SEQUENCE: 'KEY_CATEGORY_PRODUCT_MAP_DISPLAY_SEQUENCE',
  KEY_REDEEM_GROUP_LIST: 'KEY_REDEEM_GROUP_LIST',
  KEY_REDEEM_GROUP_ERROR: 'KEY_REDEEM_GROUP_ERROR',
  KEY_REDEEM_GROUP_CODE: 'KEY_REDEEM_GROUP_CODE',
  KEY_REDEEM_GROUP_CODE_DESCRIPTION: 'KEY_REDEEM_GROUP_CODE_DESCRIPTION',
  KEY_REDEEM_GROUP_SELECT_LOCATION: 'KEY_REDEEM_GROUP_SELECT_LOCATION',
  KEY_REDEEM_GROUP_SELECT_DATE: 'KEY_REDEEM_GROUP_SELECT_DATE',
  KEY_REDEEM_GROUP_SELECT_TIME: 'KEY_REDEEM_GROUP_SELECT_TIME',
  KEY_REDEEM_GROUP_REDEEM_TYPE: 'KEY_REDEEM_GROUP_REDEEM_TYPE',
  KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP: 'KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP',
  KEY_REDEEM_GROUP_END_REDEEM_TIMESTAMP: 'KEY_REDEEM_GROUP_END_REDEEM_TIMESTAMP',
  // KEY_REDEEM_GROUP_REDEEM_LOCATION_LIST: 'KEY_REDEEM_GROUP_REDEEM_LOCATION_LIST',
  KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID: 'KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID',
  KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME: 'KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME',
  KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME: 'KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME',
  KEY_REDEEM_GROUP_REDEEM_LOCATION_INTERVAL: 'KEY_REDEEM_GROUP_REDEEM_LOCATION_INTERVAL',
  KEY_PRODUCT_ERROR: 'KEY_PRODUCT_ERROR',
  KEY_PRODUCT_CODE: 'KEY_PRODUCT_CODE',
  KEY_PRODUCT_LIST: 'KEY_PRODUCT_LIST',
  KEY_PRODUCT_TYPE: 'KEY_PRODUCT_TYPE',
  KEY_PRODUCT_CHANNEL: 'KEY_PRODUCT_CHANNEL',
  KEY_PRODUCT_REDEEM_GROUP_CODE: 'KEY_PRODUCT_REDEEM_GROUP_CODE',
  KEY_PRODUCT_CATE_TYPE: 'KEY_PRODUCT_CATE_TYPE',
  // KEY_PRODUCT_BRAND_CODE: 'KEY_PRODUCT_BRAND_CODE',
  // KEY_PRODUCT_SETTLEMENT_CONFIG: 'KEY_PRODUCT_SETTLEMENT_CONFIG',
  KEY_PRODUCT_SYSTEM_GROUP: 'KEY_PRODUCT_SYSTEM_GROUP',
  KEY_PRODUCT_TC_NAME: 'KEY_PRODUCT_TC_NAME',
  KEY_PRODUCT_EN_NAME: 'KEY_PRODUCT_EN_NAME',
  KEY_PRODUCT_SC_NAME: 'KEY_PRODUCT_SC_NAME',
  // KEY_PRODUCT_TC_DESCRIPTION: 'KEY_PRODUCT_TC_DESCRIPTION',
  // KEY_PRODUCT_EN_DESCRIPTION: 'KEY_PRODUCT_EN_DESCRIPTION',
  // KEY_PRODUCT_SC_DESCRIPTION: 'KEY_PRODUCT_SC_DESCRIPTION',
  // KEY_PRODUCT_TC_TAC: 'KEY_PRODUCT_TC_TAC',
  // KEY_PRODUCT_EN_TAC: 'KEY_PRODUCT_EN_TAC',
  // KEY_PRODUCT_SC_TAC: 'KEY_PRODUCT_SC_TAC',
  // KEY_PRODUCT_TC_REDEEM_DESCRIPTION: 'KEY_PRODUCT_TC_REDEEM_DESCRIPTION',
  // KEY_PRODUCT_EN_REDEEM_DESCRIPTION: 'KEY_PRODUCT_EN_REDEEM_DESCRIPTION',
  // KEY_PRODUCT_SC_REDEEM_DESCRIPTION: 'KEY_PRODUCT_SC_REDEEM_DESCRIPTION',
  // KEY_PRODUCT_TC_DISCOUNT_DESCRIPTION: 'KEY_PRODUCT_TC_DISCOUNT_DESCRIPTION',
  // KEY_PRODUCT_EN_DISCOUNT_DESCRIPTION: 'KEY_PRODUCT_EN_DISCOUNT_DESCRIPTION',
  // KEY_PRODUCT_SC_DISCOUNT_DESCRIPTION: 'KEY_PRODUCT_SC_DISCOUNT_DESCRIPTION',
  KEY_PRODUCT_START_DISPLAY_TIMESTAMP: 'KEY_PRODUCT_START_DISPLAY_TIMESTAMP',
  KEY_PRODUCT_END_DISPLAY_TIMESTAMP: 'KEY_PRODUCT_END_DISPLAY_TIMESTAMP',
  KEY_PRODUCT_DISPLAY_SEQUENCE: 'KEY_PRODUCT_DISPLAY_SEQUENCE',
  KEY_PRODUCT_ORIGINAL_PRICE: 'KEY_PRODUCT_ORIGINAL_PRICE',
  KEY_PRODUCT_SELLING_PRICE: 'KEY_PRODUCT_SELLING_PRICE',
  KEY_PRODUCT_PERSON_LIMIT: 'KEY_PRODUCT_PERSON_LIMIT',
  KEY_PRODUCT_QUANTITY_PER_ORDER: 'KEY_PRODUCT_QUANTITY_PER_ORDER',
  KEY_PRODUCT_DAILY_QUOTA: 'KEY_PRODUCT_DAILY_QUOTA',
  KEY_PRODUCT_BUFFER_REDEEM_DAY: 'KEY_PRODUCT_BUFFER_REDEEM_DAY',
  KEY_PRODUCT_SELF_REDEEM: 'KEY_PRODUCT_SELF_REDEEM',
  KEY_PRODUCT_SELF_REFUND: 'KEY_PRODUCT_SELF_REFUND',
  KEY_PRODUCT_SEARCHABLE: 'KEY_PRODUCT_SEARCHABLE',
  KEY_PRODUCT_SHAREABLE: 'KEY_PRODUCT_SHAREABLE',
  KEY_PRODUCT_PACKAGE_CASH_COUPON: 'KEY_PRODUCT_PACKAGE_CASH_COUPON',
  KEY_PRODUCT_REQUIRE_FILL_IN_CONTACT_PERSON: 'KEY_PRODUCT_REQUIRE_FILL_IN_CONTACT_PERSON',
  KEY_PRODUCT_REQUIRE_FILL_IN_MOBILE_NUMBER: 'KEY_PRODUCT_REQUIRE_FILL_IN_MOBILE_NUMBER',
  KEY_PRODUCT_REQUIRE_FILL_IN_EMAIL: 'KEY_PRODUCT_REQUIRE_FILL_IN_EMAIL',
  KEY_SUB_PRODUCT_VALI_CODE: 'KEY_SUB_PRODUCT_VALI_CODE',
  KEY_PRODUCT_SUB_PRODUCT_MAP_LIST: 'KEY_PRODUCT_SUB_PRODUCT_MAP_LIST',
  KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE: 'KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE',
  KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY: 'KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY',
  KEY_PRODUCT_IMAGE_LIST: 'KEY_PRODUCT_IMAGE_LIST',
  KEY_PRODUCT_IMAGE_VALI_CODE: 'KEY_PRODUCT_IMAGE_VALI_CODE',
  KEY_PRODUCT_IMAGE_TYPE: 'KEY_PRODUCT_IMAGE_TYPE',
  KEY_PRODUCT_IMAGE_DISPLAY_SEQUENCE: 'KEY_PRODUCT_IMAGE_DISPLAY_SEQUENCE',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST_BLACKOUT_PERIOD_VALI_CODE: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST_BLACKOUT_PERIOD_VALI_CODE',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST_REST_URL_ID: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST_REST_URL_ID',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST_START_TIMESTAMP: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST_START_TIMESTAMP',
  KEY_PRODUCT_BLACKOUT_PERIOD_LIST_END_TIMESTAMP: 'KEY_PRODUCT_BLACKOUT_PERIOD_LIST_END_TIMESTAMP',
  KEY_PRODUCT_ATTRIBUTE_LIST: 'KEY_PRODUCT_ATTRIBUTE_LIST',
  KEY_PRODUCT_ATTRIBUTE_VALI_CODE: 'KEY_PRODUCT_ATTRIBUTE_VALI_CODE',
  KEY_PRODUCT_ATTRIBUTE_KEY: 'KEY_PRODUCT_ATTRIBUTE_KEY',
  KEY_PRODUCT_ATTRIBUTE_TYPE: 'KEY_PRODUCT_ATTRIBUTE_TYPE',
  KEY_ATTRIBUTE_NAME_TC: 'KEY_ATTRIBUTE_NAME_TC',
  KEY_ATTRIBUTE_NAME_EN: 'KEY_ATTRIBUTE_NAME_EN',
  KEY_ATTRIBUTE_NAME_SC: 'KEY_ATTRIBUTE_NAME_SC',
  KEY_ATTRIBUTE_DISPLAY: 'KEY_ATTRIBUTE_DISPLAY',
  KEY_ATTRIBUTE_DISPLAY_SEQUENCE: 'KEY_ATTRIBUTE_DISPLAY_SEQUENCE',
  KEY_PRODUCT_OPTION_LIST: 'KEY_PRODUCT_OPTION_LIST',
  KEY_ATTRIBUTE_OPTION_VALI_CODE: 'KEY_ATTRIBUTE_OPTION_VALI_CODE',
  KEY_ATTRIBUTE_OPTION_CODE: 'KEY_ATTRIBUTE_OPTION_CODE',
  KEY_ATTRIBUTE_OPTION_TC_VALUE: 'KEY_ATTRIBUTE_OPTION_TC_VALUE',
  KEY_ATTRIBUTE_OPTION_EN_VALUE: 'KEY_ATTRIBUTE_OPTION_EN_VALUE',
  KEY_ATTRIBUTE_OPTION_SC_VALUE: 'KEY_ATTRIBUTE_OPTION_SC_VALUE',
  KEY_ATTRIBUTE_CRITERIA: 'KEY_ATTRIBUTE_CRITERIA',
  KEY_ATTRIBUTE_CRITERIA_MAX: 'KEY_ATTRIBUTE_CRITERIA_MAX',
  KEY_ATTRIBUTE_CRITERIA_MIN: 'KEY_ATTRIBUTE_CRITERIA_MIN',
  KEY_ATTRIBUTE_DEFAULT_VALUE: 'KEY_ATTRIBUTE_DEFAULT_VALUE',
  KEY_PRODUCT_LOT_INVENTORY_ERROR: 'KEY_PRODUCT_LOT_INVENTORY_ERROR',
  KEY_PRODUCT_LOT_INVENTORY_LIST: 'KEY_PRODUCT_LOT_INVENTORY_LIST',
  KEY_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE: 'KEY_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE',
  KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE: 'KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE',
  KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_ID: 'KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_ID',
  KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_CODE: 'KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_CODE',
  KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC: 'KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC',
  KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY: 'KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY',
  KEY_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE: 'KEY_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE',
  KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY: 'KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_PRODUCT_CODE: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_PRODUCT_CODE',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY',
  KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE: 'KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE',
}

export const slideshowBulkCreateValidationConstants = {
  KEY_TYPE: 'KEY_TYPE',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_END_DISPLAY_TIMESTAMP: 'KEY_END_DISPLAY_TIMESTAMP',
  KEY_END_TIME: 'KEY_END_TIME',
  KEY_STATUS: 'KEY_STATUS',
  KEY_IMAGE: 'KEY_IMAGE',
  KEY_MEDIA_KEY: 'KEY_MEDIA_KEY',
  KEY_COS_FILE_URL: 'KEY_COS_FILE_URL',
  KEY_RESTURLID: 'KEY_RESTURLID',
  KEY_SLIDESHOW: 'KEY_SLIDESHOW',
}

export const merchantShopValidationConstants = {
  KEY_SHOP_TC_NAME: 'KEY_SHOP_TC_NAME',
  KEY_SHOP_SC_NAME: 'KEY_SHOP_SC_NAME',
  KEY_SHOP_EN_NAME: 'KEY_SHOP_EN_NAME',
  KEY_ADDRESS_TC_NAME: 'KEY_ADDRESS_TC_NAME',
  KEY_ADDRESS_SC_NAME: 'KEY_ADDRESS_SC_NAME',
  KEY_ADDRESS_EN_NAME: 'KEY_ADDRESS_EN_NAME',
  KEY_SHOP_CONTACT_NUMBER: 'KEY_SHOP_CONTACT_NUMBER',
  KEY_SHOP_LOGO: 'KEY_SHOP_LOGO',
  KEY_BR_IMAGE: 'KEY_BR_IMAGE',
  KEY_PLAN_CODE: 'KEY_PLAN_CODE',
  KEY_SHOP_INFO: 'KEY_SHOP_INFO',
  KEY_SHOP_FRONT_IMAGE: 'KEY_SHOP_FRONT_IMAGE',
  KEY_MERCHANT_USER_LIST: 'KEY_MERCHANT_USER_LIST',
}

export const queueTemplateValidationConstants = {
  KEY_QUEUE_TEMPLATE_ERROR: 'KEY_QUEUE_TEMPLATE_ERROR',
  KEY_TIME_SECTION_ID: 'KEY_TIME_SECTION_ID',
  KEY_TIME_SECTION_EN_LABEL: 'KEY_TIME_SECTION_EN_LABEL',
  KEY_TIME_SECTION_TC_LABEL: 'KEY_TIME_SECTION_TC_LABEL',
  KEY_TIME_SECTION_SC_LABEL: 'KEY_TIME_SECTION_SC_LABEL',
  KEY_TIME_SECTION_START_TIME: 'KEY_TIME_SECTION_START_TIME',
  KEY_TIME_SECTION_END_TIME: 'KEY_TIME_SECTION_END_TIME',
  KEY_TABLE_TYPE_START_TIME: 'KEY_TABLE_TYPE_START_TIME',
  KEY_TABLE_TYPE_END_TIME: 'KEY_TABLE_TYPE_END_TIME',
  KEY_TABLE_TYPE_LIST: 'KEY_TABLE_TYPE_LIST',
  KEY_TABLE_TYPE_ERROR: 'KEY_TABLE_TYPE_ERROR',
  KEY_TABLE_TYPE: 'KEY_TABLE_TYPE',
  KEY_PARENT_TABLE_TYPE: 'KEY_PARENT_TABLE_TYPE',
  KEY_TABLE_TYPE_BACKGROUND_COLOR: 'KEY_TABLE_TYPE_BACKGROUND_COLOR',
  KEY_TABLE_TYPE_FOREGROUND_COLOR: 'KEY_TABLE_TYPE_FOREGROUND_COLOR',
  KEY_TABLE_TYPE_MOBILE_BACKGROUND_COLOR: 'KEY_TABLE_TYPE_MOBILE_BACKGROUND_COLOR',
  KEY_TABLE_TYPE_MOBILE_FOREGROUND_COLOR: 'KEY_TABLE_TYPE_MOBILE_FOREGROUND_COLOR',
  KEY_MIN_SIZE: 'KEY_MIN_SIZE',
  KEY_MAX_SIZE: 'KEY_MAX_SIZE',
  KEY_SIZE_SELECTABLE: 'KEY_SIZE_SELECTABLE',
  KEY_DAILY_QUOTA: 'KEY_DAILY_QUOTA',
  KEY_PERSONAL_QUOTA: 'KEY_PERSONAL_QUOTA',
  KEY_NEXT_REMIND: 'KEY_NEXT_REMIND',
  KEY_MOBILE_DISPLAY: 'KEY_MOBILE_DISPLAY',
  KEY_MOBILE_READ_ONLY: 'KEY_MOBILE_READ_ONLY',
  KEY_KIOS_DISPLAY: 'KEY_KIOS_DISPLAY',
  KEY_KIOS_READ_ONLY: 'KEY_KIOS_READ_ONLY',
  KEY_TABLE_TYPE_STATUS: 'KEY_TABLE_TYPE_STATUS',
  KEY_TIME_SECTION_STATUS: 'KEY_TIME_SECTION_STATUS',
  KEY_CUT_OFF_MIN: 'KEY_CUT_OFF_MIN',
  KEY_GROUPED_SECTION: 'KEY_GROUPED_SECTION',
  KEY_KIOSK_REQUEST_TICKET_START_TIME: 'KEY_KIOSK_REQUEST_TICKET_START_TIME',
  KEY_MOBILE_REQUEST_TICKET_START_TIME: 'KEY_MOBILE_REQUEST_TICKET_START_TIME',
}

export const queueProfileValidationConstants = {
  KEY_EN_NAME: 'KEY_SHOP_EN_NAME',
  KEY_TC_NAME: 'KEY_SHOP_TC_NAME',
  KEY_SC_NAME: 'KEY_SHOP_SC_NAME',
  KEY_REF_TEMPLATE_CODE: 'KEY_REF_TEMPLATE_CODE',
  KEY_STATUS: 'KEY_STATUS'
}

export const queueTransferProfileValidationConstants = {
  KEY_EN_NAME: 'KEY_SHOP_EN_NAME',
  KEY_TC_NAME: 'KEY_SHOP_TC_NAME',
  KEY_SC_NAME: 'KEY_SHOP_SC_NAME',
  KEY_REF_TEMPLATE_CODE: 'KEY_REF_TEMPLATE_CODE',
  KEY_STATUS: 'KEY_STATUS'
}

export const counterProfileValidationConstants = {
  KEY_EN_NAME: 'KEY_SHOP_EN_NAME',
  KEY_TC_NAME: 'KEY_SHOP_TC_NAME',
  KEY_SC_NAME: 'KEY_SHOP_SC_NAME',
  KEY_COUNTER_LIST: 'KEY_COUNTER_LIST',
}

export const counterValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_RESTURLID: 'KEY_RESTURLID',
  KEY_TEMPLATE_CODE: 'KEY_TEMPLATE_CODE',
}

export const staffValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_PASSWORD: 'KEY_PASSWORD',
  KEY_RESTURLID: 'KEY_RESTURLID',
  KEY_STAFF_ROLE: 'KEY_STAFF_ROLE',
  KEY_REF_CODE: 'KEY_REF_CODE',
  KEY_SURNAME: 'KEY_SURNAME',
  KEY_FIRST_NAME: 'KEY_FIRST_NAME',
  KEY_DISPLAY_NAME: 'KEY_DISPLAY_NAME',
  KEY_EMAIL: 'KEY_EMAIL',
  KEY_COUNTRY_CODE: 'KEY_COUNTRY_CODE',
  KEY_MOBILE: 'KEY_MOBILE',
}

export const queueTemplateMapValidationConstants = {
  KEY_QUEUE_TEMPLATE_MAP_ERROR: 'KEY_QUEUE_TEMPLATE_MAP_ERROR',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_TYPE: 'KEY_TYPE',
  KEY_DAY_OF_WEEK: 'KEY_DAY_OF_WEEK',
  KEY_START_DATE: 'KEY_START_DATE',
  KEY_END_DATE: 'KEY_END_DATE',
  KEY_TEMPLATE_CODE: 'KEY_TEMPLATE_CODE',
}

export const printTemplateValidationConstants = {
  KEY_PRINT_TEMPLATE_ERROR: 'KEY_PRINT_TEMPLATE_ERROR',
  KEY_CODE: 'KEY_CODE',
  KEY_TYPE: 'KEY_TYPE',
  KEY_TEMPLATE_ERROR: 'KEY_TEMPLATE_ERROR',
  KEY_TEMPLATE_TYPE: 'KEY_TEMPLATE_TYPE',
  KEY_TEMPLATE_VALUE: 'KEY_TEMPLATE_VALUE',
  KEY_TEMPLATE_LABEL_ERROR: 'KEY_TEMPLATE_LABEL_ERROR',
  KEY_TEMPLATE_LABEL_TITLE: 'KEY_TEMPLATE_LABEL_TITLE',
  KEY_TEMPLATE_LABEL_EN: 'KEY_TEMPLATE_LABEL_EN',
  KEY_TEMPLATE_LABEL_TC: 'KEY_TEMPLATE_LABEL_TC',
  KEY_TEMPLATE_LABEL_SC: 'KEY_TEMPLATE_LABEL_SC',
  KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL: 'KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL',
  KEY_TEMPLATE_VALUE_TIME_BRACKET: 'KEY_TEMPLATE_VALUE_TIME_BRACKET',
  KEY_TEMPLATE_VALUE_TIME_FORMAT: 'KEY_TEMPLATE_VALUE_TIME_FORMAT',
  KEY_TEMPLATE_VALUE_DATE_FORMAT: 'KEY_TEMPLATE_VALUE_DATE_FORMAT',
}

export const reservationTemplateValidationConstants = {
  KEY_RESERVATION_TEMPLATE_ERROR: 'KEY_RESERVATION_TEMPLATE_ERROR',
  KEY_TIME_SECTION_ID: 'KEY_TIME_SECTION_ID',
  KEY_TIME_SECTION_EN_LABEL: 'KEY_TIME_SECTION_EN_LABEL',
  KEY_TIME_SECTION_TC_LABEL: 'KEY_TIME_SECTION_TC_LABEL',
  KEY_TIME_SECTION_SC_LABEL: 'KEY_TIME_SECTION_SC_LABEL',
  KEY_TIME_SECTION_START_TIME: 'KEY_TIME_SECTION_START_TIME',
  KEY_TIME_SECTION_END_TIME: 'KEY_TIME_SECTION_END_TIME',
  KEY_TABLE_TYPE_LIST: 'KEY_TABLE_TYPE_LIST',
  KEY_TABLE_TYPE_ERROR: 'KEY_TABLE_TYPE_ERROR',
  KEY_TABLE_TYPE: 'KEY_TABLE_TYPE',
  KEY_GROUPED_SECTION: 'KEY_GROUPED_SECTION',
  KEY_RESERVATION_TYPE: 'KEY_RESERVATION_TYPE',
  KEY_MIN_SIZE: 'KEY_MIN_SIZE',
  KEY_MAX_SIZE: 'KEY_MAX_SIZE',
  KEY_AUTO_ACCEPT_FLAG: 'KEY_AUTO_ACCEPT_FLAG',
  KEY_AUTO_REJECT_FLAG: 'KEY_AUTO_REJECT_FLAG',
  KEY_LIMIT_MINUTE: 'KEY_LIMIT_MINUTE',
  KEY_REMINDER_MINUTE: 'KEY_REMINDER_MINUTE',
  KEY_QUOTA: 'KEY_QUOTA',
  KEY_CUT_OFF_HOUR: 'KEY_CUT_OFF_HOUR',
  KEY_CUT_OFF_MINUTE: 'KEY_CUT_OFF_MINUTE',
  KEY_DEFAULT_STATUS: 'KEY_DEFAULT_STATUS',
  KEY_TABLE_TYPE_STATUS: 'KEY_TABLE_TYPE_STATUS',
  KEY_TIME_SECTION_STATUS: 'KEY_TIME_SECTION_STATUS',
}

export const reservationTemplateMapValidationConstants = {
  KEY_RESERVATION_TEMPLATE_MAP_ERROR: 'KEY_RESERVATION_TEMPLATE_MAP_ERROR',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_TYPE: 'KEY_TYPE',
  KEY_DAY_OF_WEEK: 'KEY_DAY_OF_WEEK',
  KEY_START_DATE: 'KEY_START_DATE',
  KEY_END_DATE: 'KEY_END_DATE',
  KEY_TEMPLATE_CODE: 'KEY_TEMPLATE_CODE',
}

export const chargeDateValidationConstants = {
  KEY_CHARGE_DATE_ERROR: 'KEY_CHARGE_DATE_ERROR',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_DATE: 'KEY_DATE',
  KEY_START_DATE: 'KEY_START_DATE',
  KEY_END_DATE: 'KEY_END_DATE',
  KEY_CHARGE_CODE: 'KEY_CHARGE_CODE',
}

export const adminDeviceValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_DEVICE_TYPE: 'KEY_DEVICE_TYPE',
  KEY_ACTIVATION_CODE: 'KEY_ACTIVATION_CODE',
  KEY_REPORT: 'KEY_REPORT',
  KEY_TAKEAWAYS: 'KEY_TAKEAWAYS',
  KEY_QUEUE: 'KEY_QUEUE',
  KEY_PRE_ORDER: 'KEY_PRE_ORDER',
  KEY_TAG: 'KEY_TAG',
  KEY_RESERVE: 'KEY_RESERVE',
  KEY_BANQUET: 'KEY_BANQUET',
  KEY_ECOUPON: 'KEY_ECOUPON',
  KEY_MENU: 'KEY_MENU',
  KEY_ECOUPON_DISTRIBUTE: 'KEY_ECOUPON_DISTRIBUTE',
}

export const pickupValidationConstants = {
  KEY_TYPE_CODE: 'KEY_TYPE_CODE',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_STATUS: 'KEY_STATUS',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_FOREGROUND_COLOR: 'KEY_FOREGROUND_COLOR',
  KEY_BACKGROUND_COLOR: 'KEY_BACKGROUND_COLOR',
}

export const campaignDescriptionValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_TYPE: 'KEY_TYPE',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
}

export const tncValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_TYPE: 'KEY_TYPE',
  KEY_STATUS: 'KEY_STATUS',
}

export const rejectReasonValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_STATUS: 'KEY_STATUS',
  KEY_EN_REASON: 'KEY_EN_REASON',
  KEY_TC_REASON: 'KEY_TC_REASON',
  KEY_SC_REASON: 'KEY_SC_REASON',
}

export const reservationConfigValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_DEFAULT_CHARGE: 'KEY_DEFAULT_CHARGE',
  KEY_EMAIL: 'KEY_EMAIL',
  KEY_GROUPED_SECTION_LIMIT: 'KEY_GROUPED_SECTION_LIMIT',
}

export const restaurantMediaValidationConstants = {
  KEY_COS_FILE_TC_URL: 'KEY_COS_FILE_TC_URL',
  KEY_COS_FILE_TC_KEY: 'KEY_COS_FILE_TC_KEY',
  KEY_COS_FILE_EN_URL: 'KEY_COS_FILE_EN_URL',
  KEY_COS_FILE_EN_KEY: 'KEY_COS_FILE_EN_KEY',
  KEY_COS_FILE_SC_URL: 'KEY_COS_FILE_SC_URL',
  KEY_COS_FILE_SC_KEY: 'KEY_COS_FILE_SC_KEY',
  KEY_FILE_TYPE: 'KEY_FILE_TYPE',
  KEY_DISPLAY_SEQUENCE: 'KEY_DISPLAY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_MEDIA_KEY: 'KEY_MEDIA_KEY',
  KEY_TYPE: 'KEY_TYPE',
}

export const restaurantBlackoutValidationConstants = {
  KEY_STATUS: 'KEY_STATUS',
  KEY_START_TIMESTAMP: 'KEY_START_TIMESTAMP',
  KEY_END_TIMESTAMP: 'KEY_END_TIMESTAMP',
  KEY_TYPE: 'KEY_TYPE',
  KEY_REFCODE: 'KEY_REFCODE',
}

export const restaurantSlideshowValidationConstants = {
  KEY_COS_FILE_URL: 'KEY_COS_FILE_URL',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_MEDIA_KEY: 'KEY_MEDIA_KEY',
  KEY_TYPE: 'KEY_TYPE',
  KEY_REFCODE: 'KEY_REFCODE',
  KEY_RESTURLID: 'KEY_RESTURLID',
  KEY_START_DISPLAY_TIMESTAMP: 'KEY_START_DISPLAY_TIMESTAMP',
  KEY_END_DISPLAY_TIMESTAMP: 'KEY_END_DISPLAY_TIMESTAMP',
}

export const bannerValidationConstants = {
  KEY_BANNER_ERROR: 'KEY_BANNER_ERROR',
  KEY_TYPE: 'KEY_TYPE',
  KEY_REF_CODE: 'KEY_REF_CODE',
  KEY_START_DISPLAY_DATE: 'KEY_START_DISPLAY_DATE',
  KEY_END_DISPLAY_DATE: 'KEY_END_DISPLAY_DATE',
  KEY_TC_IMAGE: 'KEY_TC_IMAGE',
  KEY_SC_IMAGE: 'KEY_SC_IMAGE',
  KEY_EN_IMAGE: 'KEY_EN_IMAGE',
  KEY_ACTION_TYPE: 'KEY_ACTION_TYPE',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_PARAMETERS: 'KEY_PARAMETERS',
  KEY_PARAMETERS_EN: 'KEY_PARAMETERS_EN',
  KEY_PARAMETERS_TC: 'KEY_PARAMETERS_TC',
  KEY_PARAMETERS_SC: 'KEY_PARAMETERS_SC',
  KEY_PRODUCT_CODE: 'KEY_PRODUCT_CODE',
  KEY_PRODUCT_CODE_EN: 'KEY_PRODUCT_CODE_EN',
  KEY_PRODUCT_CODE_TC: 'KEY_PRODUCT_CODE_TC',
  KEY_PRODUCT_CODE_SC: 'KEY_PRODUCT_CODE_SC',
  KEY_PIXEL_NAME: 'KEY_PIXEL_NAME',
  KEY_PIXEL_NAME_EN: 'KEY_PIXEL_NAME_EN',
  KEY_PIXEL_NAME_TC: 'KEY_PIXEL_NAME_TC',
  KEY_PIXEL_NAME_SC: 'KEY_PIXEL_NAME_SC',
  KEY_PIXEL_HAVE_REF: 'KEY_PIXEL_HAVE_REF',
  KEY_PIXEL_HAVE_REF_EN: 'KEY_PIXEL_HAVE_REF_EN',
  KEY_PIXEL_HAVE_REF_TC: 'KEY_PIXEL_HAVE_REF_TC',
  KEY_PIXEL_HAVE_REF_SC: 'KEY_PIXEL_HAVE_REF_SC',
  KEY_FOLDER_TYPE: 'KEY_FOLDER_TYPE',
  KEY_FOLDER_TYPE_EN: 'KEY_FOLDER_TYPE_EN',
  KEY_FOLDER_TYPE_TC: 'KEY_FOLDER_TYPE_TC',
  KEY_FOLDER_TYPE_SC: 'KEY_FOLDER_TYPE_SC',
  KEY_PRODUCT_CAT_CODE: 'KEY_PRODUCT_CAT_CODE',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
  KEY_CHILDCODE_EN: 'KEY_CHILDCODE_EN',
  KEY_CHILDCODE_TC: 'KEY_CHILDCODE_TC',
  KEY_CHILDCODE_SC: 'KEY_CHILDCODE_SC',
  KEY_CONFIG_MAP_START_DISPLAY_DATE: 'KEY_CONFIG_MAP_START_DISPLAY_DATE',
  KEY_CONFIG_MAP_END_DISPLAY_DATE: 'KEY_CONFIG_MAP_END_DISPLAY_DATE',
}

export const merchantUserValidationConstants = {
  KEY_EMAIL: 'KEY_EMAIL',
  KEY_USERNAME: 'KEY_USERNAME',
  KEY_PASSWORD: 'KEY_PASSWORD',
  KEY_USER_ROLE: 'KEY_USER_ROLE',
  KEY_ENABLED: 'KEY_ENABLED',
  KEY_ENABLED_MULTI_LOGIN: 'KEY_ENABLED_MULTI_LOGIN',
  KEY_REST_URL_ID_LIST: 'KEY_REST_URL_ID_LIST',
  KEY_PRIVILEGE_ATTRIBUTE: 'KEY_PRIVILEGE_ATTRIBUTE',
  KEY_PRIVILEGE_RESTURLID: 'KEY_PRIVILEGE_RESTURLID',
  KEY_PRIVILEGE_GROUP_CODE: 'KEY_PRIVILEGE_GROUP_CODE',
  KEY_MERCHANT_ROLE: 'KEY_MERCHANT_ROLE',
  KEY_HAS_ERROR: 'KEY_HAS_ERROR',
}

export const merchantUserGroupValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_EMAIL: 'KEY_EMAIL',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_DESCRIPTION: 'KEY_DESCRIPTION',
  KEY_PROFILE: 'KEY_PROFILE',
  KEY_ALIPAYHK_RATE: 'KEY_ALIPAYHK_RATE',
  KEY_WECHATPAY_RATE: 'KEY_WECHATPAY_RATE',
  KEY_STRIPE_RATE: 'KEY_STRIPE_RATE',
  KEY_YED_RATE: 'KEY_YED_RATE',
  KEY_MPAY_RATE: 'KEY_MPAY_RATE',
  KEY_GULU_RATE: 'KEY_GULU_RATE',
  KEY_SALE_SETTLEMENT_PROPORTION: 'KEY_SALE_SETTLEMENT_PROPORTION',
  KEY_REDEMPTION_SETTLEMENT_PROPORTION: 'KEY_REDEMPTION_SETTLEMENT_PROPORTION',
  KEY_EXPIRED_SETTLEMENT_RATE: 'KEY_EXPIRED_SETTLEMENT_RATE',
  KEY_GROUP_DESCRIPTION: 'KEY_GROUP_DESCRIPTION',
  KEY_START_TIMESTAMP: 'KEY_START_TIMESTAMP',
  KEY_END_TIMESTAMP: 'KEY_END_TIMESTAMP',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
}

export const merchantSalesUserValidationConstants = {
  KEY_USERNAME: 'KEY_USERNAME',
  KEY_PASSWORD: 'KEY_PASSWORD',
}

export const urlMapValidationConstants = {
  KEY_AGENT_TYPE: 'KEY_AGENT_TYPE',
  KEY_ACTION_TYPE: 'KEY_ACTION_TYPE',
  KEY_CUSTOM_FIELD: 'KEY_CUSTOM_FIELD',
  KEY_PRODUCT_CODE: 'KEY_PRODUCT_CODE',
  KEY_PIXEL_NAME: 'KEY_PIXEL_NAME',
  KEY_PIXEL_HAVE_REF: 'KEY_PIXEL_HAVE_REF',
  KEY_FOLDER_TYPE: 'KEY_FOLDER_TYPE',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_TYPE: 'KEY_TYPE',
  KEY_CODE: 'KEY_CODE',
  KEY_MAP_CODE: 'KEY_MAP_CODE',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
  KEY_URL: 'KEY_URL',
  KEY_CUSTOM_URL: 'KEY_CUSTOM_URL',
  KEY_REAL_REST_URL_ID: 'KEY_REAL_REST_URL_ID',
  KEY_RESTAURANT_URL_TYPE: 'KEY_RESTAURANT_URL_TYPE',
}

export const productMapValidationConstants = {
  KEY_PRODUCT_MAP_ERROR: 'KEY_PRODUCT_MAP_ERROR',
  KEY_DISPLAY_SEQUENCE: 'KEY_DISPLAY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
}

export const emailTemplateValidationConstants = {
  KEY_TYPE: 'KEY_TYPE',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_HTML_TC_CONTENT: 'KEY_HTML_TC_CONTENT',
  KEY_PLAIN_TEXT_TC_CONTENT: 'KEY_PLAIN_TEXT_TC_CONTENT',
  KEY_STATUS: 'KEY_STATUS',
}

export const brandValidationConstants = {
  KEY_GROUP_ID: 'KEY_GROUP_ID',
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_LOGO_IMAGE: 'KEY_LOGO_IMAGE',
  KEY_STATUS: 'KEY_STATUS',
}

export const brandGroupValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_STATUS: 'KEY_STATUS',
}

export const systemGroupValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_GROUP_EMAILS: 'KEY_GROUP_EMAILS',
}

export const systemUserValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_EMAIL: 'KEY_EMAIL',
  KEY_USER_ROLE: 'KEY_USER_ROLE',
  KEY_PRIVILEGE_ATTRIBUTE: 'KEY_PRIVILEGE_ATTRIBUTE',
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_STATUS: 'KEY_STATUS',
  KEY_PASSWORD: 'KEY_PASSWORD',
  KEY_USERNAME: 'KEY_USERNAME',
}

export const restaurantPaymentValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_PAYMENT_CODE: 'KEY_PAYMENT_CODE',
  KEY_CARD_TYPE: 'KEY_CARD_TYPE',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_SYSTEM_GROUP_NAME: 'KEY_SYSTEM_GROUP_NAME',
}

export const restaurantTagValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_ORDER_SEQUENCE: 'KEY_ORDER_SEQUENCE',
  KEY_EN_IMAGE: 'KEY_EN_IMAGE',
  KEY_TC_IMAGE: 'KEY_TC_IMAGE',
  KEY_SC_IMAGE: 'KEY_SC_IMAGE',
  KEY_STATUS: 'KEY_STATUS',
}

export const restaurantMapValidationConstants = {
  KEY_REST_URL_ID: 'KEY_REST_URL_ID',
  KEY_STATUS: 'KEY_STATUS',
  KEY_REST_MAP_ERROR: 'KEY_REST_MAP_ERROR',
}

export const areaValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_LANDMARK_ORDER: 'KEY_LANDMARK_ORDER',
  KEY_STATUS: 'KEY_STATUS',
}

export const selectTagValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_DISPLAY_SEQUENCE: 'KEY_DISPLAY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_TYPE: 'KEY_TYPE',
  KEY_SELECT_TAG_ERROR: 'KEY_SELECT_TAG_ERROR',
}

export const districtValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_AREA_ID: 'KEY_AREA_ID',
  KEY_DISTRICT_LEVEL: 'KEY_DISTRICT_LEVEL',
  KEY_MENU_CHI_ORDER: 'KEY_MENU_CHI_ORDER',
  KEY_LATITUDE: 'KEY_LATITUDE',
  KEY_LONGITUDE: 'KEY_LONGITUDE',
  KEY_STATUS: 'KEY_STATUS',
}

export const landmarkValidationConstants = {
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_LANDMARK_ORDER: 'KEY_LANDMARK_ORDER',
  KEY_FAVORITE: 'KEY_FAVORITE',
  KEY_STATUS: 'KEY_STATUS',
}

export const landmarkDistrictMapValidationConstants = {
  KEY_LANDMARK_DISTRICT_MAP_ERROR: 'KEY_LANDMARK_DISTRICT_MAP_ERROR',
  KEY_STATUS: 'KEY_STATUS',
}

export const folderValidationConstants = {
  KEY_FOLDER_CODE: 'KEY_FOLDER_CODE',
  KEY_EN_NAME: 'KEY_EN_NAME',
  KEY_TC_NAME: 'KEY_TC_NAME',
  KEY_SC_NAME: 'KEY_SC_NAME',
  KEY_EN_IMAGE: 'KEY_EN_IMAGE',
  KEY_TC_IMAGE: 'KEY_TC_IMAGE',
  KEY_SC_IMAGE: 'KEY_SC_IMAGE',
  KEY_STATUS: 'KEY_STATUS',
}

export const folderMapValidationConstants = {
  KEY_FOLDER_MAP_ERROR: 'KEY_FOLDER_MAP_ERROR',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_STATUS: 'KEY_STATUS',
}

export const pushValidationConstants = {
  KEY_NAME: 'KEY_NAME',
  KEY_EN_MESSAGE: 'KEY_EN_MESSAGE',
  KEY_TC_MESSAGE: 'KEY_TC_MESSAGE',
  KEY_SC_MESSAGE: 'KEY_SC_MESSAGE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_DELIVERY_DATE: 'KEY_DELIVERY_DATE',
  KEY_INBOX: 'KEY_INBOX',
  KEY_NOTIFICATION: 'KEY_NOTIFICATION',
  KEY_SYSTEM_MESSAGE: 'KEY_SYSTEM_MESSAGE',
  KEY_ACTION_TYPE: 'KEY_ACTION_TYPE',
  KEY_PARAMETERS: 'KEY_PARAMETERS',
  KEY_PRODUCT_CODE: 'KEY_PRODUCT_CODE',
  KEY_PIXEL_NAME: 'KEY_PIXEL_NAME',
  KEY_PIXEL_HAVE_REF: 'KEY_PIXEL_HAVE_REF',
  KEY_FOLDER_TYPE: 'KEY_FOLDER_TYPE',
  KEY_MOBILE_LIST: 'KEY_MOBILE_LIST',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
}

export const landingItemValidationConstants = {
  KEY_ITEM_TYPE: 'KEY_ITEM_TYPE',
  KEY_EN_TITLE: 'KEY_EN_TITLE',
  KEY_TC_TITLE: 'KEY_TC_TITLE',
  KEY_SC_TITLE: 'KEY_SC_TITLE',
  KEY_EN_SUB_TITLE: 'KEY_EN_SUB_TITLE',
  KEY_TC_SUB_TITLE: 'KEY_TC_SUB_TITLE',
  KEY_SC_SUB_TITLE: 'KEY_SC_SUB_TITLE',
  KEY_EN_IMAGE: 'KEY_EN_IMAGE',
  KEY_TC_IMAGE: 'KEY_TC_IMAGE',
  KEY_SC_IMAGE: 'KEY_SC_IMAGE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_START_DISPLAY_TIMESTAMP: 'KEY_START_DISPLAY_TIMESTAMP',
  KEY_END_DISPLAY_TIMESTAMP: 'KEY_END_DISPLAY_TIMESTAMP',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_TYPE: 'KEY_TYPE',
  KEY_CODE: 'KEY_CODE,',
  KEY_PARAMETERS: 'KEY_PARAMETERS',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
  KEY_ERROR: 'KEY_ERROR',
  KEY_ITEM_ERROR: 'KEY_ITEM_ERROR',
}

export const landingValidationConstants = {
  KEY_ITEM_TYPE: 'KEY_ITEM_TYPE',
  KEY_EN_TITLE: 'KEY_EN_TITLE',
  KEY_TC_TITLE: 'KEY_TC_TITLE',
  KEY_SC_TITLE: 'KEY_SC_TITLE',
  KEY_EN_SUB_TITLE: 'KEY_EN_SUB_TITLE',
  KEY_TC_SUB_TITLE: 'KEY_TC_SUB_TITLE',
  KEY_SC_SUB_TITLE: 'KEY_SC_SUB_TITLE',
  KEY_EN_IMAGE: 'KEY_EN_IMAGE',
  KEY_TC_IMAGE: 'KEY_TC_IMAGE',
  KEY_SC_IMAGE: 'KEY_SC_IMAGE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_START_DISPLAY_TIMESTAMP: 'KEY_START_DISPLAY_TIMESTAMP',
  KEY_END_DISPLAY_TIMESTAMP: 'KEY_END_DISPLAY_TIMESTAMP',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_TYPE: 'KEY_TYPE',
  KEY_CODE: 'KEY_CODE,',
  KEY_PARAMETERS: 'KEY_PARAMETERS',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
  KEY_ERROR: 'KEY_ERROR',
  KEY_ITEM_ERROR: 'KEY_ITEM_ERROR',
}

export const landingSectionValidationConstants = {
  KEY_EN_TITLE: 'KEY_EN_TITLE',
  KEY_TC_TITLE: 'KEY_TC_TITLE',
  KEY_SC_TITLE: 'KEY_SC_TITLE',
  KEY_STATUS: 'KEY_STATUS',
  KEY_SEQUENCE: 'KEY_SEQUENCE',
  KEY_START_DISPLAY_TIMESTAMP: 'KEY_START_DISPLAY_TIMESTAMP',
  KEY_END_DISPLAY_TIMESTAMP: 'KEY_END_DISPLAY_TIMESTAMP',
  KEY_FOLDER_CODE: 'KEY_FOLDER_CODE',
  KEY_SERVICE_TYPE: 'KEY_SERVICE_TYPE',
  KEY_TYPE: 'KEY_TYPE',
  KEY_CODE: 'KEY_CODE,',
  KEY_PARAMETERS: 'KEY_PARAMETERS',
  KEY_CHILDCODE: 'KEY_CHILDCODE',
  KEY_SECTION_TYPE: 'KEY_SECTION_TYPE',
  KEY_ERROR: 'KEY_ERROR',
  KEY_ITEM_ERROR: 'KEY_ITEM_ERROR',
  KEY_TITLE_COLOR: 'KEY_TITLE_COLOR',
  KEY_CHANNEL: 'KEY_CHANNEL',
  KEY_ITEM_TYPE: 'KEY_ITEM_TYPE',
}

export const printConfigValidationConstants = {
  KEY_DEVICE_TYPE: 'KEY_DEVICE_TYPE',
  KEY_SHORT_TICKET_TNC: 'KEY_SHORT_TICKET_TNC'
}

export const paymentValidationConstants = {
  KEY_PLAN_CODE: 'KEY_PLAN_CODE',
  KEY_PAYMENT_CODE: 'KEY_PAYMENT_CODE',
  KEY_CHEQUE_NUMBER: 'KEY_CHEQUE_NUMBER',
  KEY_BANK_CODE: 'KEY_BANK_CODE',
  KEY_BANK_ACCOUNT_NUMBER: 'KEY_BANK_ACCOUNT_NUMBER',
  KEY_CHEQUE_IMAGE: 'KEY_CHEQUE_IMAGE',
}

export const merchantInfoValidationConstants = {
  KEY_CONTACT_PERSON: 'KEY_CONTACT_PERSON',
  KEY_CONTACT: 'KEY_CONTACT',
  KEY_JOB_TITLE: 'KEY_JOB_TITLE',
  KEY_BANK_ACCOUNT: 'KEY_BANK_ACCOUNT',
  KEY_BANK_ACCOUNT_NAME: 'KEY_BANK_ACCOUNT_NAME',
  KEY_BANK_CODE: 'KEY_BANK_CODE',
  KEY_BANK_CONTACT_PERSON: 'KEY_BANK_CONTACT_PERSON',
  KEY_BANK_CONTACT: 'KEY_BANK_CONTACT',
}

export const restaurantApproveValidationConstants = {
  KEY_REJECT_REASON: 'KEY_REJECT_REASON',
}

export const brandApproveValidationConstants = {
  KEY_REJECT_REASON: 'KEY_REJECT_REASON',
}

export const passwordValidationConstants = {
  KEY_NEW_PASSWORD: 'KEY_NEW_PASSWORD',
  KEY_VERIFY_PASSWORD: 'KEY_VERIFY_PASSWORD',
}
