export const tcMessages = {
  serverError: '未能完成請求',
  loginError: '請先登入',

  //menu
  shopMenu: '店舖',
  approveMenu: '批准',
  takeawayMenu: '外賣',
  productMenu: '產品',
  restaurantTag: '餐廳標籤',
  productCreateMenuAdmin: '新增產品',
  productCreateMenuFinance: '產品草稿',
  merchantMenu: 'Merchant',
  tools: '工具',
  configuration: '設置',
  template: '模板',
  brand: '品牌',
  location: '地點',
  changePassword: '更改密碼',

  //warning
  selectWarning: '請選擇餐廳！',
  warning: '警告: ',
  warningContent: '您的所有行為都會受到監控！',

  //btn
  login: '登錄',
  create: '新增',
  back: '返回',
  next: '下一頁',
  saveAsDraft: '另存為草稿',
  approve: '批准',
  unpublish: '下架',
  publish: '上架',
  finishBtn: '完成',
  submit: '提交',
  save: '保存',
  remove: '移除',
  applyToAllRelatedShop: '套用至所有相關店舖',
  applyToAll: '全部套用',
  apply: '套用',
  search: '搜索',
  cancelBtn: '取消',
  close: '關閉',
  clear: '清除',
  Clone: '新增',
  clearCache: '清除緩存',
  clearRedisCache: '清除緩存',
  clearGuluWebSiteDetailCache: '清除 GULU 網站詳細信息',
  add: '新增',
  edit: '編輯',
  rejectBtn: '拒絕',
  select: '選擇',
  confirm: '確定',
  deleteBtn: '刪除',
  resetPassword: '重設密碼',
  reset: '重設',
  resetAccountNameAndPassword: '重設用戶名稱及密碼',
  preview: '預覽',

  //dialog
  backEdit: '繼續編輯',
  backList: '返回目錄',
  backCreate: '返回新增',
  backClone: '返回新增',
  goToMapList: 'Go To Map List',
  goToMapCreate: 'Go To Map Create',
  dialogTitle: '資訊',
  editSuccess: '編輯成功！',
  editFailure: '編輯失敗!',
  createSuccess: '新增成功！',
  createFailure: '新增失敗！',
  cloneSuccess: '新增成功！ 請按刷新鍵刷新列表！(**Searchable 已設訂為 false!)',
  cloneFailure: '新增失敗！',
  addSuccess: '新增成功！',
  addFailure: '新增失敗！',
  clearSuccess: '清除成功!',
  clearFailure: '清除失敗!',
  clearCacheSuccess: '清除成功!',
  clearCacheFailure: '清除失敗!',
  resetSuccess: '重置成功!',
  resetFailure: '重置失敗!',
  previewSuccess: '預覽成功！',
  previewFailure: '預覽失敗!',
  testingSuccess: '測試成功！',
  testingFailure: '測試失敗!',
  approveSuccess: '批准成功！',
  approveFailure: '批准成功！',
  rejectSuccess: '批准失敗！',
  rejectFailure: '拒絕失敗!',
  paymentSuccess: '付款成功！',
  paymentFailure: '付款失敗!',
  deleteSuccess: '刪除成功！',
  deleteFailure: '刪除失敗!',
  applySuccess: '應用成功！',
  applyFailure: '應用失敗!',

  //CustomizenName
  nameCustomize: '名稱（自動將繁體翻譯成簡體）',
  descriptionCustomize: '描述（自動將繁體翻譯成簡體）',
  multiActionParameters: 'Multiple Language Parameters',

  //Time
  lastModified: '上次修改時間：',
  updateTimestamp: '更新時間',
  lastLoginTimestamp: '上次登入時間',
  resetUpdateTimestamp: '上次重置時間',

  //other
  sales: '銷售員',
  true: 'true',
  false: 'false',
  pleaseEnter: 'Please Enter',
  applyToMap: 'Apply To Map',
  downloadQRCode: '下載二維碼',
  zhHK: '繁中',
  sc: '簡中',
  en: 'English',

  //select
  active: 'A (生效)',
  deactive: 'D (不生效)',
  pending: 'P (待定)',
  merchant: 'M (Merchant)',
  overWritten: 'O (重複)',
  reject: 'R (拒絕)',
  removed: 'R (落架)',
  N: 'N (不活躍)',
  complete: 'C (完成)',
  a_assigned: 'A (已分配)',
  delete: 'D (刪除)',
  inactive: 'I (不活動)',
  processing: 'P (處理中)',
  available: 'A (可用)',
  cancel: 'C (取消)',
  finish: 'F (完成)',
  s_checkIn: 'S (報到)',
  reminder: 'M (提醒)',
  sentOut: 'O (送出)',
  hold: 'H (保留)',
  expired: 'E (過期)',
  void: 'V (Void)',
  accept: 'A (接受)',
  s_assigned: 'S (已分配)',
  waiting: 'W (等待)',
  hold_release: 'L (Hold Release)',
  maxims_cancel: 'MC (Maxims Cancel)',
  c_checkIn: 'C (報到)',
  all: '全部',
  tobeapprove: 'TBA (待批准)',
  yes: '是',
  no: '不',

  resource: {
    login: {
      title: 'Please Login!',
      username: 'username',
      password: 'password',
    },

    restaurant: {
      mainTitle: '餐廳',
      restUrlId: 'Rest Url ID',
      status: '狀態',
      restRefId: 'Ref ID',
      enName: '餐廳名稱(英文)',
      tcName: '餐廳名稱(繁體中文)',
      scName: '餐廳名稱(簡體中文)',
      salesName: '銷售人員姓名',
      salesCreateTimestamp: '銷售創建時間戳',
      startDisplayTimestamp: '開始顯示時間戳',
      //餐廳列表
      address: '地址',
      imageUrl: 'imageUrl',
      refID: 'Ref ID',
      name: '名稱',
      salesCreateStartDate: '銷售創建開始日期',
      salesCreateEndDate: '銷售創建結束日期',
      //restaurantClone
      newRestUrlID: 'New Rest Url ID',
      cloneTitle: 'Clone Restaurant',
      systemGroup: 'System Group',
      slideshow: 'Slideshow',
      banner: 'Banner',
      payment: '付款',
      systemGroupGorilla: 'System Group Gorilla',
      merchantUserCreate: 'Merchant User Create',
      //restaurantEdit
      info: '資料',
      qrCode: '二維碼',
      queue: '排隊',
      reservation: '訂座',
      pickup: '提取叫號',
      merchant: '商戶',
      media: '媒體',
      product: '產品',
      select_tag: 'Select Tag',
      restaurantName: '餐廳名稱',
      eSearchingName: '搜索名稱(英文)',
      tcSearchingName: '搜索名稱(繁體中文)',
      scSearchingName: '搜索名稱(簡體中文)',
      weChatEName: '微信餐廳名稱(英文)',
      weChatTcName: '微信餐廳名稱(繁體中文)',
      weChatScName: '微信餐廳名稱(簡體中文)',
      information: '資料',
      brandCode: '品牌',
      editBrandCode: '編輯品牌',
      addBrandCode: '新增品牌',
      clearBrandCode: '清除品牌',
      editBrandAndBrandGroupID: '編輯品牌及集團',
      groupID: '集團',
      editGroupID: '編輯集團',
      addGroupID: '新增集團',
      restaurantType: '餐廳類型',
      phoneAddress: '電話及地址',
      phone: '電話',
      district: '區域',
      addDistrictID: '新增區域',
      tcAddress: '地址(繁體中文)',
      enAddress: '地址(英文)',
      scAddress: '地址(簡體中文)',
      description: '詳細資料',
      enTicketMessage: 'Ticket Message(英文)',
      tcTicketMessage: 'Ticket Message(繁體中文)',
      scTicketMessage: 'Ticket Message(簡體中文)',
      enOpening: '營業時間(英文)',
      tcOpening: '營業時間(繁體中文)',
      scOpening: '營業時間(簡體中文)',
      config: 'Configuration',
      paymentMethod: '付款方法',
      avgSpend: '平均花費',
      enLastOrderTime: '最後訂單時間(英文)',
      tcLastOrderTime: '最後訂單時間(繁體中文)',
      scLastOrderTime: '最後訂單時間(簡體中文)',
      enDescription: '詳細資料(英文)',
      tcDescription: '詳細資料(繁體中文)',
      scDescription: '詳細資料(簡體中文)',
      aigensId: "Aigens ID(Maxim's)",
      searchable: 'Searchable',
      theme: '主題',
      showBanner: '顯示橫幅',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      weChatNameCustomize: ' 微信名稱（與 「名稱」 相同）',
      searchingNameCustomize: '搜索名稱（自動將繁體翻譯成簡體）',
      ticketMessageCustomize: 'Ticket Message（自動將繁體翻譯成簡體）',
      openingCustomize: '營業時間（自動將繁體翻譯成簡體）',
      lastOrderTimeCustomize: '最後訂單時間（自動將繁體翻譯成簡體）',
      descriptionCustomize: '詳細資料（自動將繁體翻譯成簡體）',
      addressCustomize: '地址（自動將繁體翻譯成簡體）',
      image: '圖片',
      googleMap: '地圖',
      uploadImage: '上載圖片',
      relatedPictures: '相關圖片',
      dropFile: '按此上載圖片',
      retaurantBannerList: '餐廳橫幅列表',
      campaignDescription: '信息顯示',
      tnc: '條款及細則',
      editTitle: '編輯餐廳',
      salesAndContact: '銷售員及聯繫人',
      contact: '聯絡電話',
      contactPerson: '聯繫人',
      jobTitle: '職稱',
      folderMapList: 'Folder Map List (Restaurant)',
      addFolderMap: 'Add Restaurant',
      urlGenerator: 'Url Generator',
      website: 'Website',
      specificLang: 'Language (EN/TC/SC only work above GULU 4.2.11, Do NOT add if you need wechat scan)',
      universalLink: 'Open App / Website',
      defaultService: '默認服務',
      generate: 'Generate',
      url: 'Url',
    },

    //Map
    Map: {
      mainTitle: '地圖',
      addressEdit: '地址編輯',
      chineseAddressSave: '中文地址 (儲存版本):',
      chineseAddress: '中文地址:',
      engAddressSave: '英文地址 (儲存版本):',
      engAddress: '英文地址:',
      lat: '緯度:',
      lng: '經度:',
      zoom: '縮放地圖',
      search: '搜尋(地址):',
      reset: '重置',
    },

    //RestaurantMedia
    RestaurantMedia: {
      mainTitle: '媒體',
      type: '類型',
      cosFileUrl: '媒體',
      fileType: '文件類型',
      displaySequence: '顯示順序',
      status: '狀態',
      image: '圖像',
      cosFileEnUrl: '圖像(英文)',
      cosFileTcUrl: '圖像(繁體中文)',
      cosFileScUrl: '圖像(簡體中文)',
      tcImage: '圖像(繁體中文)',
      enImage: '圖像(英文)',
      scImage: '圖像(簡體中文)',
      editTitle: '媒體編輯',
      createTitle: '新增媒體',
      info: '資料',
    },

    RestaurantBlackout: {
      mainTitle: 'Blackout',
      type: 'Type',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      refCode: 'Refcode',
      status: '狀態',
      info: '資料',
      editTitle: 'Blackout Period Edit',
      createTitle: 'Blackout Period Create',
    },

    RestaurantSlideshow: {
      mainTitle: 'Slideshow',
      restUrlId: 'Rest Url Id',
      restRefId: 'Ref ID',
      address: '地址',
      type: 'Type',
      mediaUrl: 'Media',
      media: 'Media',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      sequence: '序列',
      status: '狀態',
      editTitle: 'Slideshow Edit',
      createTitle: 'Slideshow Create',
      info: '資料',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      id: 'ID',
      name: '名稱',
      showExpired: 'Show Expired',
      deactivate: 'Delete',
      pending: 'Pending',
      active: 'Active',
      showDefaultSlideshow: 'Show Default Slideshow',
      addRestaurantMap: 'Add Restaurant Map',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
    },

    //urlMap
    UrlMap: {
      mainTitle: 'Url Map',
      type: '種類',
      mapCode: 'Map Code',
      mapValue: 'Map Value',
      agentType: '代理類型',
      url: 'URL',
      serviceType: '服務類型',
      parameter: 'Parameter',
      status: '狀態',
      restUrlIdQRCode: 'Rest Url ID (反掃用)',
      actionType: 'Action Type',
      action: 'Action',
      weChat: '微信',
      productCode: 'Product Code',
      categoryCode: 'Category Code',
      brandCode: 'Brand Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      from: 'From',
      title: 'Title',
      query: 'Query',
      refId: 'Ref Id',
      ticketId: 'Ticket Id',
      infoId: 'Info Id',
      groupCode: 'Group Code',
      ecouponId: 'ECoupon Id',
      code: 'Code',
      channel: '頻道',
      name: '名稱',
      tcName: '產品名稱(繁體中文)',
      enName: '產品名稱(英文)',
      scName: '產品名稱(簡體中文)',
      productChannel: '產品頻道',
      productEnName: '產品名稱(英文)',
      productTcName: '產品名稱(繁體中文)',
      productScName: '產品名稱(簡體中文)',
      productStatus: '產品狀態',
      editTitle: 'UrlMap編輯',
      createTitle: '新增UrlMap',
      info: '資料',
      productParent: '選擇品牌/類別',
      eCouponParent: '選擇品牌/類別',
      ecouponCode: 'E-Coupon ',
      customUrl: 'Custom URL',
      restaurantUrlType: 'Restaurant URL Type',
    },

    //status
    Status: {
      mainTitle: '狀態',
      orderStatus: '訂單狀態',
      queueStatus: '排隊狀態',
      queueVisible: 'Queue Visible',
      queueSpringboardAvailable: 'Queue Springboard Available',
      queueWebTvAvailable: 'Queue Web TV Available',
      queueTicketDeletable: 'Queue Ticket Deletable',
      reserveStatus: '備用狀態',
      reservationVisible: 'Reservation Visible',
      takeawayStatus: '外賣狀態',
      takeawayVisible: 'Takeaway Visible',
      takeawayDeliveryStatus: '外賣交貨狀態',
      takeawayPickupStatus: '外賣接送狀態',
      takeawayPreorderStatus: '外賣預訂狀態',
      wxTakeawayStatus: 'wx外賣狀態',
      wxProductStatus: 'wx產品狀態',
      menuStatus: '菜單狀態',
      menuVisible: 'Menu Visible',
      ecouponDistributeStatus: 'ecoupon分配狀態',
      banquetStatus: '宴會狀態',
      banquetVisible: 'Banquet Visible',
      havePreorder: '有預購',
      autoSwitchTimeSection: '自動切換時間',
      adminLiteStatus: '管理員精簡版狀態',
      vipStatus: 'VIP狀態',
      turboStatus: 'Turbo狀態',
      bookmarkStatus: '書籤狀態',
      reviewStatus: '評論狀態',
      socialPhotoStatus: '社交照片狀態',
      shareRestStatus: 'Restaurant Share Status',
      gpsRequired: '需要 GPS',
      backList: '返回餐廳目錄',
      editTitle: '狀態編輯',
      retaurantBannerList: '餐廳橫幅列表',
      info: '資料',
      restaurant: '餐廳',
      reserve: '備用',
      queue: '排隊',
      takeaway: '外賣',
      product: '產品',
      banquet: '宴會',
      other: '其他',
      queueWebAvailable: 'Queue Web Available',
      pickup: '提取叫號',
      pickupVisible: 'Pickup Visible',
      guest: 'Guest',
      guestReservationStatus: 'Guest Reservation Status',
      guestTicketStatus: 'Guest Ticket Status',
      notifier: '通知方式',
      email: '電郵',
      sms: 'SMS',
      whatsapp: 'Whatsapp',
      reservationWebAvailable: 'Reservation Web Available',
      queueWebReadOnly: 'Queue Web Read Only',
      queueWebOfficialReadOnly: 'Queue GULU Web Read Only',
      pickupStatus: 'Pickup Status',
    },

    QueueConfig: {
      mainTitle: '取票配置',
      showTableNo: 'Show Table No',
      timeSectionQuota: 'Time Section Concurrent Quota',
      rollTagShiftValue: 'Roll Tag Shift Value',
      ticketExpiredMinute: 'Ticket Expired Minute',
      repeatQueue: 'Repeat Queue',
      checksumExpiryLimit: 'Checksum Expiry Limit',
      queueSteps: 'Queue Steps',
      addQueueSteps: 'Add Queue Steps',
      removeQueueSteps: 'Remove Queue Steps',
      campaign: 'Campaign',
      campaignQueue: 'Campaign Queue',
      tagSequenceDisplay: 'Tag Sequence Display',
      genFromTemplate: 'Gen From Template',
      campaignStartTimestamp: 'Campaign Start Timestamp',
      campaignEndTimestamp: 'Campaign End Timestamp',
      preparationDay: 'Preparation Day',
      delay: 'Delay',
      delayEnable: 'Delay Enable',
      delayMaxMinute: 'Delay Max Minute',
      delayReminderAnswerSecond: 'Delay Reminder Answer Second',
      delayReminderTriggerSecond: 'Delay Reminder Trigger Second',
      releaseQuotaOnDelete: 'Release Quota On Delete',
      counterProfileCode: 'Default Counter Profile Code',
      requiredPass: 'Pass Required',
      limitDistance: 'Limit Distance',
      queuePeriod: 'Queue Period',
      customData: 'Custom Data',
      email: '電子郵件',
      mobile: '手機',
      personalLimitType: 'Personal Limit Type',
      queueType: 'Queue Type',
      counterTagType: 'Counter Tag Type',
      deliveryType: 'Delivery Type',
      requiredOtp: 'Required OTP',
      redisSequence: 'Redis Sequence',
      quickQueueGroupName: 'Quick Queue Group Name',
      quotaType: 'Quota Type',
      rollTagType: 'Roll Tag Type',
      inputCheckInSize: 'Input Check In Size',
      minCheckInSize: 'Min Check In Size',
      maxCheckInSize: 'Max Check In Size',
      showTableType: 'Show Table Type',
      displayType: 'Display Type',
      checkInType: 'Check In Type',
      eventCheckInType: 'Event Check In Type',
      reset: 'Reset Current Time Section',
      resetConfrim: 'Are you confirm to reset current time section?',
      editTitle: 'Queue Config Edit',
      info: '資料',
      dailyResetTime: 'Daily Reset Time',
      campaignType: 'Campaign Type',
      addResourcesSteps: 'Add Resources Steps',
      resourcesSteps: 'Resources Steps',
      autoTimeType: 'Auto Time Type',
      checkOutType: 'Check Out Type',
      checkInBeforeMinute: 'Check In Before Minute',
      checkInLateMinute: 'Check In Late Minute',
      webShare: 'Web Share',
      mobileShare: 'Mobile Share',
      pleaseEnter: '請輸入',
      enterPassCode: '以重置時段',
      passCode: 'PassCode',
      dailyReset: 'Daily Reset',
      resetQueueStatus: 'Reset Queue Status',
      transferable: 'Transferable',
      useTransferableTicketLabel: 'Use Transferable Ticket Label',
      autoRequestTicketStatus: 'Auto Request Ticket Status',
      autoTask: 'Auto Task',
      autoSwitchTimeSection: 'Auto Switch Time Section',
      maxTicketPerSection: 'Max Ticket Per Section',
      limitTicketInMinute: 'Limit Ticket In Minute',
    },

    PrintConfig: {
      mainTitle: '打印配置',
      printTemplateCode: '打印模板代碼',
      deviceType: '設備類型',
      ticketRemarks: '條款及細則',
      printQrcodeType: '二維碼打印類型',
      logoImage: 'Logo 圖像',
      mallLogoImage: 'Mall Logo 圖像',
      mallKeypadButtonImage: 'Mall Keypad Button 圖像',
      headerImage: 'Header 圖像',
      footerImage: 'Footer 圖像',
      rightLargeImage: 'Right Large 圖像',
      rightSmallImage: 'Right Small 圖像',
      qrcodeImage: '二維碼圖像',
      portraitRectangleImage: 'Portrait Rectangle 圖像',
      portraitSquareImage: 'Portrait Square 圖像',
      landscapeRectangleImage: 'Landscape Rectangle 圖像',
      landscapeSquareImage: 'Landscape Square 圖像',
      portraitHeaderImage: 'Portrait Header 圖像',
      portraitFooterImage: 'Portrait Footer 圖像',
      landscapeHeaderImage: 'Landscape Header 圖像',
      landscapeFooterImage: 'Landscape Footer 圖像',
      printTemplateSection: 'Print Template Section',
      kioskSection: 'Kiosk Section',
      shopAppSection: 'Shop App Version 1.1.8 (46)+',
      displayQrcodeUrl: 'Display QR Code URL',
      qrCodePreview: '螢幕二維碼預覽',
      printTemplateType: '打印模板類型',
      shortTicketTnc: 'Short Ticket Tnc (Max 500 Characters)',
      shortPrintTemplateCode: 'Short Print Template Code',
      service: 'Service',
      from: 'From',
      addMoreParam: 'Add More Param To URL',
    },

    QueueTemplate: {
      mainTitle: 'Queue Template',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      description: '描述',
      status: '狀態',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: '標籤',
      addRestaurant: '添加餐廳列表',
      addQueueTemplateMap: 'Add Queue Template Map',
      resetCurrentTimeSection: 'Reset Current Time Section',
      editTitle: 'Queue Template Edit',
      addQueueTemplate: 'Add Time Section',
      autoOrderTimeSection: 'Order Time Section',
      autoOrderTableType: 'Order Table Type',
      createTitle: 'Queue Template Create',
      info: '資料',
      createQueueTemplate: 'Create Ticket Time Section',
      cloneQueueTemplate: 'Clone Queue Template: ',
      applyQueueTemplate: 'Apply Queue Template: ',
    },

    TimeSection: {
      mainTitle: 'Time Section',
      title: 'Time Section',
      timeSectionList: 'Time Section',
      timeSectionId: '時間段 ID',
      groupedSection: 'Grouped Section',
      type: '類型',
      timeSectionDescription: '時間段描述',
      startTime: '開始時間',
      endTime: '結束時間',
      timeSectionEnLabel: '時間段標籤(英文)',
      timeSectionTcLabel: '時間段標籤(繁體中文)',
      timeSectionScLabel: '時間段標籤(簡體中文)',
      isAutoTranslateTimeSectionLabel: '時間段標籤（自動將繁體翻譯成簡體）',
      status: '狀態',
      editTitle: 'Time Section Edit',
      createTitle: 'Time Section Create',
      info: '資料',
      defaultTimeSectionSetting: 'Default Time Section Setting',
      isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
      isSyncRequestTicketTimeFieldsWithAllTableTypes: 'Sync Request Ticket Time Fields With All Table Types',
      kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
      mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
    },

    TableType: {
      mainTitle: 'Table Type',
      title: 'Table Type',
      status: '狀態',
      defaultStatus: '默認狀態',
      tableTypeList: 'Table Type List',
      tableType: 'Table Type',
      parentTableType: 'Parent Table Type',
      tableTypeEnLabel: 'Table Type En Label',
      tableTypeTcLabel: 'Table Type Tc Label',
      tableTypeScLabel: 'Table Type Sc Label',
      isAutoTranslateTableTypeScLabel: 'Table Type Label (Auto Translate TC to SC)',
      tableTypeMobileEnLabel: 'Table Type Mobile En Label',
      tableTypeMobileTcLabel: 'Table Type Mobile Tc Label',
      tableTypeMobileScLabel: 'Table Type Mobile Sc Label',
      isAutoTranslateTableTypeMobileScLabel: 'Table Type Mobile Label (Auto Translate TC to SC)',
      tableTypeMobileBackgroundColor: 'Mobile BackgroundColor',
      tableTypeMobileForegroundColor: 'Mobile Foreground Color',
      applyTableTypeBackgroundColor: 'Apply Default Color Set',
      applyTableTypeMobileBackgroundColor: 'Apply Default Mobile Color Set',
      minSize: 'Min Size',
      maxSize: 'Max Size',
      sizeSelectable: 'Size Selectable',
      ticketType: 'Ticket Type',
      ticketTypeEnLabel: 'Ticket Type En Label',
      ticketTypeTcLabel: 'Ticket Type Tc Label',
      ticketTypeScLabel: 'Ticket Type Sc Label',
      nextRemind: 'Next Remind',
      dailyQuota: '每日配額',
      personalQuota: 'Personal Quota',
      mobileDisplay: 'Mobile Get Ticket Display',
      mobileTagDisplay: 'Mobile Tag Display',
      mobileReadOnly: 'Mobile Read Only',
      kioskDisplay: 'Kiosk Get Ticket Display',
      kioskTagDisplay: 'Kiosk Tag Display',
      kioskReadOnly: 'Kiosk Read Only',
      editTitle: 'Table Type Edit',
      createTitle: 'Table Type Create',
      info: '資料',
      //Reservation
      groupedSection: 'Grouped Section',
      reservationType: 'Reservation Type',
      autoAcceptFlag: 'AutoAccept Flag',
      autoRejectFlag: 'autoRejectFlag',
      limitMinute: 'Limit Minute',
      reminderMinute: 'Reminder Minute',
      quota: 'Quota',
      cutOffHour: 'Cut Off Hour',
      cutOffMinute: 'Cut Off Minute',
      cutOffTime: 'Cut Off Time',
      startTime: '開始時間',
      endTime: '結束時間',
      type: '類型',
      timeSectionDescription: '時間段描述',
      isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
      isSyncRequestTicketTimeFieldsWithAllTableTypes: 'Sync Request Ticket Time Fields With All Table Types',
      queueType: 'Queue Type',
      displayType: 'Display Type',
      rollTagType: 'Roll Tag Type',
      tableTypeBackgroundColor: 'Table Type Background Color',
      tableTypeForegroundColor: 'Table Type Foreground Color',
      tableTypeTicketEnLabel: 'Table Type Ticket En Label',
      tableTypeTicketTcLabel: 'Table Type Ticket Tc Label',
      tableTypeTicketScLabel: 'Table Type Ticket Sc Label',
      isAutoTranslateTableTypeTicketScLabel: 'Table Type Ticket Label (Auto Translate TC to SC)',
      groupedSectionEnLabel: 'Grouped Section En Label',
      groupedSectionTcLabel: 'Grouped Section Tc Label',
      groupedSectionScLabel: 'Grouped Section Sc Label',
      isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
      groupedSectionEnDescription: 'Grouped Section En Description',
      groupedSectionTcDescription: 'Grouped Section Tc Description',
      groupedSectionScDescription: 'Grouped Section Sc Description',
      isAutoTranslateGroupedSectionDescription: 'Grouped Section Description (Auto Translate TC to SC)',
      displaySequence: 'Display Sequence',
      voiceCode: 'Voice Code',
      kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
      mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
    },
    groupedSection: {
      editTitle: 'Grouped Section Edit',
      groupedSection: 'Grouped Section',
      groupedSectionEnLabel: 'Grouped Section En Label',
      groupedSectionTcLabel: 'Grouped Section Tc Label',
      groupedSectionScLabel: 'Grouped Section Sc Label',
      isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
      groupedSectionEnDescription: 'Grouped Section En Description',
      groupedSectionTcDescription: 'Grouped Section Tc Description',
      groupedSectionScDescription: 'Grouped Section Sc Description',
      isAutoTranslateGroupedSectionDescription: 'Grouped Section Description (Auto Translate TC to SC)',
    },
    TicketType: {
      mainTitle: 'Ticket Type',
      ticketType: 'Ticket Type Code',
      name: '名稱',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      status: '狀態',
      editTitle: 'Ticket Type Edit',
      createTitle: 'Ticket Type Create',
      info: '資料',
      foregroundColor: 'Foreground Color',
      backgroundColor: 'Background Color',
      mobileImage: 'Mobile Image',
      kioskImage: 'Kiosk Image',
      ticketTypeMapList: 'Ticket Type Map List',
      queueTemplateCode: 'Queue Template Code',
      restUrlId: 'Rest Url Id',
      restName: 'Restaurant Name',
      queueTemplateTimeSectionLabel: 'Queue Template Time Section Label',
      queueTemplateTableTypeLabel: 'Queue Template Table Type Label',
      queueTemplateTimeSectionId: 'Queue Template Time Section Id',
      queueTemplateTableType: 'Queue Template Table Type',
    },

    QueueTemplateMap: {
      mainTitle: 'Queue Template Map',
      type: '類型',
      addQueueTemplateMap: 'Add Queue Template Map',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      templateDescription: 'Template Description',
      dayOfWeek: '星期幾',
      description: '描述',
      startDate: '開始日期',
      endDate: '結束日期',
      date: '日期',
      status: '狀態',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: '標籤',
      editTitle: 'Queue Template Map Edit',
      addQueueTemplate: 'Add Ticket Time Section',
      createTitle: 'Queue Template Map Create',
      info: '資料',
      createQueueTemplate: 'Create Ticket Time Section',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
      PH: 'PH',
      selectAll: '全選',
      clearAll: '移除所有選項',
      weekday: '平日',
      holidays: '假期',
      dateRangeListTitle: 'Date Range List',
      dayOfWeekListTitle: 'Day of Week List',
      queueTemplateForToday: "Today's Queue Template",
    },
    QueueRejectReason: {
      mainTitle: 'Queue Reject Reason',
      reasonCode: 'Reason Code',
      reason: 'Reason',
      status: '狀態',
      id: 'id',
      enReason: 'En Reason',
      tcReason: 'Tc Reason',
      scReason: 'Sc Reason',
      info: '資料',
      reasonCustomize: 'Reason (Auto Translate TC to SC)',
      editTitle: 'Queue Reject Reason Edit',
      createTitle: 'Queue Reject Reason Create',
    },
    ReservationConfig: {
      mainTitle: '預約配置',
      remarksEnMessage: '備註信息(英文)',
      remarksTcMessage: '備註信息(繁體中文)',
      remarksScMessage: '備註信息(簡體中文)',
      remarksMessageCustomize: '備註信息（自動將繁體翻譯成簡體）',
      disableEnMessage: '禁用消息(英文)',
      disableTcMessage: '禁用消息(繁體中文)',
      disableScMessage: '禁用消息(簡體中文)',
      disableMessageCustomize: '禁用消息（自動將繁體翻譯成簡體）',
      reminderEnMessage: '提醒消息(英文)',
      reminderTcMessage: '提醒消息(繁體中文)',
      reminderScMessage: '提醒消息(簡體中文)',
      reminderMessageCustomize: '提醒消息（自動將繁體翻譯成簡體）',
      noTimeSectionEnMessage: '無時間段消息(英文)',
      noTimeSectionTcMessage: '無時間段消息(繁體中文)',
      noTimeSectionScMessage: '無時間段消息(簡體中文)',
      noTimeSectionMessageCustomize: '無時間段消息（自動將繁體翻譯成簡體）',
      afterDay: 'After Day',
      reserveDay: '預約日期',
      timeInterval: '時間間隔',
      haveConfirm: '有確認',
      showTableNumber: 'Show Table Number',
      maxConcurrentReservation: 'Max Concurrent Reservation',
      showRemarks: '顯示備註',
      haveCharge: '有收費',
      chargeType: '收費類型',
      chargeByDefault: '默認收費',
      chargeCode: '收費代碼',
      defaultCharge: '默認收費($)',
      chargeEnDescription: '收費描述(英文)',
      chargeTcDescription: '收費描述(繁體中文)',
      chargeScDescription: '收費描述(簡體中文)',
      chargeDescriptionCustomize: '收費描述(自動將繁體翻譯成簡體)',
      startTimestamp: '開始時間',
      expiredTimestamp: '過期時間',
      notificationEmail: '通知郵件',
      sendQrcodeToEmail: '發送二維碼到郵箱',
      reserveStatus: '預約狀態',
      emailError: '請輸入正確的電子郵件格式！',
      defaultChargeError: '請輸入正確的默認收費格式！',
      message: '信息',
      charge: '收費',
      other: '其他',
      deliveryType: 'Delivery Type',
      requiredOtp: 'Required OTP',
      mode: 'Mode',
      webShare: 'Web Share',
      editable: 'Editable',
      checkInBeforeMinute: 'Check In Before Minute',
      checkInLateMinute: 'Check In Late Minute',
      webMultiReserve: 'Web Multi Reserve',
      groupedSectionLimit: 'Grouped Section Limit',
    },
    ReservationRejectReason: {
      mainTitle: 'Reservation Reject Reason',
      reasonCode: 'Reason Code',
      reason: 'Reason',
      status: '狀態',
      id: 'id',
      enReason: 'En Reason',
      tcReason: 'Tc Reason',
      scReason: 'Sc Reason',
      info: '資料',
      reasonCustomize: 'Reason (Auto Translate TC to SC)',
      editTitle: 'Reservation Reject Reason Edit',
      createTitle: 'Reservation Reject Reason Create',
    },
    ReservationTemplate: {
      mainTitle: 'Reservation Template',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      description: '描述',
      inUse: '使用中',
      status: '狀態',
      effectiveDate: '生效日期',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: '標籤',
      addRestaurant: '添加餐廳列表',
      addReservationTemplateMap: 'Add Reservation Template Map List',
      resetCurrentTimeSection: 'Reset Current Time Section',
      editTitle: 'Reservation Template Edit',
      addReservationTemplate: 'Add Ticket Time Section',
      createTitle: 'Reservation Template Create',
      info: '資料',
      createReservationTemplate: 'Create Ticket Time Section',
      cloneReservationTemplate: 'Clone Reservation Template: ',
      applyReservationTemplate: 'Apply Reservation Template: ',
    },

    ReservationTemplateMap: {
      mainTitle: 'Reservation Template Map List',
      type: '類型',
      addReservationTemplateMap: 'Add Reservation Template Map List',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      templateDescription: 'Template Description',
      dayOfWeek: '星期幾',
      description: '描述',
      startDate: '開始日期',
      endDate: '結束日期',
      date: '日期',
      status: '狀態',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: '標籤',
      editTitle: 'Reservation Template Map List Edit',
      addReservationTemplate: 'Add Ticket Time Section',
      createTitle: 'Reservation Template Map List Create',
      info: '資料',
      createReservationTemplate: 'Create Ticket Time Section',
    },

    ChargeDate: {
      mainTitle: '收費日期',
      chargeCode: 'Charge Code',
      date: '日期',
      addChargeDate: '添加收費日期',
      startDate: '開始日期',
      endDate: '結束日期',
      id: 'ID',
      editTitle: '收費日期編輯',
      createTitle: '新增收費日期',
      info: '資料',
    },

    AdminDevice: {
      mainTitle: '管理設備',
      troubleShooting: 'Trouble Shooting',
      //AdminDeviceList
      deviceId: '設備ID',
      devicenName: '設備名稱',
      activationCode: '激活碼',
      deviceType: '設備類型',
      activationStatus: '激活狀態',
      deactivate: '停用',
      deactivateSuccess: '停用成功',
      adminDeviceListTitle: '管理設備列表：',
      addAdminDeviceTitle: '添加管理設備： ',
      //AdminDeviceAdd
      kioskLabel: '2 (Kiosk)',
      prLabel: '3 (PR)',
      tvLabel: '5 (TV)',
      backList: '返回餐廳目錄',
      //AdminDevice Edit
      info: '資料',
      editTitle: '管理設備編輯',
      reportStatus: '報告',
      takeawayStatus: '外賣',
      queueStatus: '排隊',
      preorderStatus: '預購',
      tagStatus: '標籤',
      reservationStatus: '保留',
      banquetStatus: '宴會',
      ecouponStatus: '環保',
      menuStatus: '菜單',
      ecouponDistributeStatus: '分發 Ecoupon',
      createTitle: '新增管理設備',
      confirmDeactivate: '確認停用管理設備？',
    },

    CampaignDescription: {
      mainTitle: '信息顯示',
      //CampaignDescriptionList
      type: '類型',
      messageCode: 'Message Code',
      title: '標題',
      enTitle: '標題(英文)',
      tcTitle: '標題(繁體中文)',
      scTitle: '標題(簡體中文)',
      sequence: '序列',
      status: '狀態',
      message: '信息',
      tcMessage: '信息(繁體中文)',
      enMessage: '信息(英文)',
      scMessage: '信息(簡體中文)',
      titleCustomize: '標題（自動將繁體翻譯成簡體）',
      messageCustomize: '信息（自動將繁體翻譯成簡體）',
      restUrlId: 'Rest Url ID',
      info: '資料',
      editTitle: '信息顯示編輯',
      createTitle: '新增信息顯示',
    },

    TnC: {
      mainTitle: '條款和條件',
      //Terms & ConditionsList
      type: '類型',
      enDescription: '描述(英文)',
      tcDescription: '描述(繁體中文)',
      scDescription: '描述(簡體中文)',
      status: '狀態',
      descriptionCustomize: '描述（自動將繁體翻譯成簡體）',
      restUrlId: 'Rest Url ID',
      info: '資料',
      editTitle: '條款和條件編輯',
      createTitle: '新增條款和條件',
      filterKey: '關鍵字',
    },

    EmailTemplate: {
      mainTitle: '電子郵件模板',
      //EmailTemplateList
      restUrlId: 'Rest Url Id',
      type: '類型',
      receiver: '接收人',
      subject: '主題',
      enSubject: '主題(英文)',
      tcSubject: '主題(繁體中文)',
      scSubject: '主題(簡體中文)',
      subjectCustomize: '主題（自動將繁體翻譯成簡體）',
      status: '狀態',
      htmlContent: 'HTML內容',
      htmlEnContent: 'HTML內容(英文)',
      htmlTcContent: 'HTML內容(繁體中文)',
      htmlScContent: 'HTML內容(簡體中文)',
      htmlContentCustomize: 'HTML內容（自動將繁體翻譯成簡體）',
      plainTextContent: '純文本內容',
      plainTextEnContent: '純文本內容(英文)',
      plainTextTcContent: '純文本內容(繁體中文)',
      plainTextScContent: '純文本內容(簡體中文)',
      plainTextContentCustomize: '純文本內容（自動將繁體翻譯成簡體）',
      addReceiver: 'Add Receiver',
      removeReceiver: 'Remove Receiver',
      info: '資料',
      editTitle: '電子郵件模板編輯',
      createTitle: '新增電子郵件模板',
    },

    PrintTemplate: {
      mainTitle: '打印模板',
      //EmailTemplateList
      id: 'Id',
      code: 'Code',
      type: '類型',
      status: '狀態',
      templateLabels: '模板標籤',
      template: '模板',
      value: 'Value',
      addTemplate: '添加模板',
      addText: 'Add Text',
      addDateFormat: 'Add Date Format',
      addTemplateLabel: '添加模板標籤',
      labelTitle: '標籤標題',
      labelEn: '標題(英文)',
      labelTc: '標題(繁體中文)',
      labelSc: '標題(簡體中文)',
      year: '年',
      month: '月',
      date: '日',
      hour: '小時',
      minute: '分鐘',
      seconds: '秒',
      timeFormat: '時間格式',
      time: '時間',
      week: '週',
      middleSymbol: '中間符號',
      withBracket: '帶括號',
      info: '資料',
      editTitle: '打印模板編輯',
      createTitle: '新增模板編輯',
      addLabel: 'Add Label',
    },

    restaurant_map: {
      mainTitle: 'Restaurant Map List',
      //restaurant_map
      tagCode: '標籤代碼',
      name: '餐廳名稱',
      restName: '餐廳名稱',
      restUrlId: 'Rest Url ID',
      status: 'Restaurant Map狀態',
      enName: '餐廳名稱(英文)',
      tcName: '餐廳名稱(繁體中文)',
      scName: '餐廳名稱(簡體中文)',
      orderSequence: '訂單序列',
      restaurantMapList: '餐廳列表',
      addRestaurantMap: '添加餐廳',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      startTimestamp: '開始時間',
      endTimestamp: '結束時間',
      sequence: '序列',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      tcPictures: '圖片(繁體中文)',
      enPictures: '圖片(英文)',
      scPictures: '圖片(簡體中文)',
      editTitle: 'Restaurant Map編輯',
      info: '資料',
      createTitle: 'Restaurant Map新增',
    },

    restaurant_category: {
      mainTitle: '類別',
      //restaurantCategoryList
      tagCode: '標籤代碼',
      name: '名稱',
      restName: '餐廳名稱',
      restUrlId: 'Rest Url ID',
      status: '狀態',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      orderSequence: '訂單序列',
      restaurantMapList: '餐廳列表',
      addRestaurantMap: '添加餐廳',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      startTimestamp: '開始時間',
      endTimestamp: '結束時間',
      sequence: '序列',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      tcPictures: '圖片(繁體中文)',
      enPictures: '圖片(英文)',
      scPictures: '圖片(簡體中文)',
      editTitle: '類別編輯',
      info: '資料',
      createTitle: '類別新增',
    },

    restaurant_cuisine: {
      mainTitle: '菜式',
      //restaurantCuisineList
      tagCode: '標籤代碼',
      name: '名稱',
      restName: '餐廳名稱',
      restUrlId: 'Rest Url ID',
      status: '狀態',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      orderSequence: '訂單序列',
      restaurantMapList: '餐廳列表',
      addRestaurantMap: '添加餐廳',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      startTimestamp: '開始時間',
      endTimestamp: '結束時間',
      sequence: '序列',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      tcPictures: '圖片(繁體中文)',
      enPictures: '圖片(英文)',
      scPictures: '圖片(簡體中文)',
      editTitle: '菜式編輯',
      info: '資料',
      createTitle: '菜式新增',
    },

    restaurant_keyword: {
      mainTitle: '關鍵詞',
      //restaurantKeywordList
      tagCode: '標籤代碼',
      name: '名稱',
      restName: '餐廳名稱',
      restUrlId: 'Rest Url ID',
      status: '狀態',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      orderSequence: '訂單序列',
      restaurantMapList: '餐廳列表',
      addRestaurantMap: '添加餐廳',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      startTimestamp: '開始時間',
      endTimestamp: '結束時間',
      sequence: '序列',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      tcPictures: '圖片(繁體中文)',
      enPictures: '圖片(英文)',
      scPictures: '圖片(簡體中文)',
      editTitle: '關鍵詞編輯',
      info: '資料',
      createTitle: '關鍵詞新增',
    },

    BrandGroup: {
      mainTitle: '餐飲集團',
      groupId: '集團ID',
      enName: '集團名稱(英文)',
      tcName: '集團名稱(繁體中文)',
      scName: '集團名稱(簡體中文)',
      status: '狀態',
      update_timestamp: '更新時間',
      //BrandGroupList
      BrandGroupListTitle: '餐飲集團列表：',
      addBrandGroupTitle: '添加餐飲集團： ',
      group_name: '集團名稱',
      //BrandGroup Edit
      editTitle: '編輯餐飲集團',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      createTitle: '新增餐飲集團',
      info: '資料',
    },

    Brand: {
      mainTitle: '餐飲品牌',
      brandCode: '品牌ID',
      enName: '品牌名稱(英文)',
      tcName: '品牌名稱(繁體中文)',
      scName: '品牌名稱(簡體中文)',
      enDescription: '詳細資料(英文)',
      tcDescription: '詳細資料(繁體中文)',
      scDescription: '詳細資料(簡體中文)',
      descriptionCustomize: '詳細資料（自動將繁體翻譯成簡體）',
      groupId: '集團ID',
      groupEnName: '集團名稱(英文)',
      groupTcName: '集團名稱(繁體中文)',
      groupScName: '集團名稱(簡體中文)',
      logoImage: 'Logo Image',
      uploadImage: '上傳圖片',
      imageList: '圖像列表',
      type: '類型',
      cosFileUrl: '媒體',
      fileType: '文件類型',
      displaySequence: '顯示順序',
      status: '狀態',
      update_timestamp: '更新時間',
      //BrandList
      BrandListTitle: '餐飲品牌列表：',
      addBrandTitle: '添加品牌： ',
      brand_name: '品牌名稱',
      //Brand Edit
      editTitle: '編輯餐飲品牌',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      createTitle: '新增餐飲品牌',
      info: '資料',
    },

    BrandProduct: {
      mainTitle: '品牌產品',
      productCode: 'Product Code',
      type: '類型',
      name: '名稱',
      channel: '頻道',
      merchantProduct: 'Merchant Product',
      status: '狀態',
    },

    Folder: {
      mainTitle: '文件夾',
      folderId: '文件夾 Id',
      folderCode: '文件夾代碼',
      type: '文件夾類型',
      name: '文件夾名稱',
      enName: '文件夾名稱(英文)',
      tcName: '文件夾名稱(繁體中文)',
      scName: '文件夾名稱(簡體中文)',
      enDescriptionHtml: 'Html描述(英文)',
      tcDescriptionHtml: 'Html描述(繁體中文)',
      scDescriptionHtml: 'Html描述(簡體中文)',
      descriptionCustomize: '描述（自動將繁體翻譯成簡體）',
      tcImage: '圖像(繁體中文)',
      enImage: '圖像(英文)',
      scImage: '圖像(簡體中文)',
      status: '文件夾狀態',
      editTitle: '編輯文件夾',
      folderMapList: 'Folder Map List',
      bannerList: '橫幅列表',
      folderBannerList: '文件夾橫幅列表',
      landmarkOrder: 'Landmark Order',
      createTitle: '新增文件夾',
      info: '資料',
      addFolderMap: 'Add Folder',
      showHidden: 'Show Hidden',
    },

    FolderMap: {
      mainTitle: 'Folder Map List',
      folderId: 'Folder Map Id',
      refCode: 'Folder Map Ref Code',
      type: 'Folder Map Type',
      name: '文件夾/餐廳名稱',
      refEnName: '文件夾/餐廳名稱(英文)',
      refTcName: '文件夾/餐廳名稱(繁體中文)',
      refScName: '文件夾/餐廳名稱(簡體中文)',
      sequence: '序列',
      status: 'Folder Map List Status',
      editTitle: 'Folder Map List Edit',
      createTitle: 'Folder Map List Create',
      info: '資料',
    },

    FolderBanner: {
      mainTitle: '橫幅',
      id: 'ID',
      refCode: 'Ref Code',
      tcImage: '橫幅圖像(繁體中文)',
      enImage: '橫幅圖像(英文)',
      scImage: '橫幅圖像(簡體中文)',
      enName: '橫幅名稱(英文)',
      tcName: '橫幅名稱(繁體中文)',
      scName: '橫幅名稱(簡體中文)',
      name: '橫幅名稱',
      type: '橫幅類型',
      webViewType: '類型',
      actionType: 'Action Type',
      action: 'Action',
      productCode: 'Product Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      startDisplayDate: '開始顯示日期',
      endDisplayDate: '結束顯示日期',
      sequence: '序列',
      status: '橫幅狀態',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      imageError: '**橫幅大小不相等！**',
      from: '來自',
      title: '標題',
      query: 'Query',
      editTitle: '編輯橫幅',
      createTitle: '新增橫幅',
      info: '資料',
      addFolderBanner: '新增文件夾橫幅',
    },

    Landing: {
      mainTitle: '登陸頁面',
      landingBannerList: '登陸頁面橫幅列表',
    },

    Ticket: {
      mainTitle: '取票',
      ticketBannerList: '取票橫幅列表',
    },

    Slideshow: {
      mainTitle: '幻燈片',
      all_slideshow: '幻燈片',
      default_slideshow: 'GULU默認',
    },

    ConfigMap: {
      mainTitle: '配置',
      serviceType: '服務類型',
      ticketStatus: '狀態',
      restUrlId: 'Rest Url Id',
      clearRestUrlId: 'Clear Rest Url Id',
      restName: '餐廳名稱',
      restTcName: '餐廳名稱(繁體中文)',
      restEnName: '餐廳名稱(英文)',
      restScName: '餐廳名稱(簡體中文)',
      channel: '頻道',
      actionType: 'Action Type',
      startDisplayDate: '開始顯示日期',
      endDisplayDate: '結束顯示日期',
      status: '狀態',
      editTitle: '編輯配置',
      configMapList: '配置列表',
      createTitle: '新增配置列表',
      addConfigMap: '新增配置',
      info: '資料',
      addRestaurant: 'Add Restaurant',
    },

    Banner: {
      mainTitle: '橫幅',
      id: 'ID',
      bannerId: 'Banner Id',
      refCode: 'Ref Code',
      tcImage: '橫幅圖像(繁體中文)',
      enImage: '橫幅圖像(英文)',
      scImage: '橫幅圖像(簡體中文)',
      enName: '橫幅名稱(英文)',
      tcName: '橫幅名稱(繁體中文)',
      scName: '橫幅名稱(簡體中文)',
      name: '名稱',
      type: '橫幅類型',
      webViewType: '類型',
      actionType: 'Action Type',
      action: 'Action',
      productCode: 'Product Code',
      categoryCode: 'Category Code',
      brandCode: 'Brand Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      startDisplayDate: '開始顯示日期',
      endDisplayDate: '結束顯示日期',
      sequence: '序列',
      status: '橫幅狀態',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      imageError: '**Banners size are not equal!**',
      from: 'From',
      title: 'Title',
      query: 'Query',
      editTitle: '編輯橫幅',
      createTitle: '新增橫幅',
      info: '資料',
      addBanner: '新增橫幅',
      productParent: '選擇品牌/類別',
      eCouponParent: '選擇E-COUPON品牌/類別',
      ecouponCode: 'E-Coupon ',
      deactivate: 'Delete',
      pending: 'Pending',
      active: 'Active',
    },

    MerchantConfig: {
      mainTitle: '商戶配置',
      queue: '排隊',
      queueRemindResetQuota: '提醒排隊重置配額',
      queueChannelStatusControl: '排隊頻道狀態控制',
      queueQuickCheckIn: '排隊快速報到',
      product: '產品',
      publishProduct: '發布產品',
      createProduct: '新增產品',
      sendProduct: '發送產品',
      counterCallEnable: 'Counter Call Enable',
      queueRejectTicketEnable: 'Queue Reject Ticket Enable',
      queueStatusPerQueueControl: 'Queue Status Per Queue Control',
      queueMultipleStatusControl: 'Queue Multiple Status Control',
      queueDisplayControl: 'Queue Display Control',
      slideshow: '幻燈片',
      guluSlideshowEnable: '使用GULU幻燈片',
      device: '裝置',
      customPassword: '自定義密碼',
      shopAppSoundSpeed: 'Shop App 語速',
      voiceCode: '聲音代碼',
      clearSoundFiles: '清除音檔',
      preorderProduct: 'Pre-order Product',
    },
    MerchantDevice: {
      mainTitle: 'Device',
      deviceType: 'Device Type',
      deviceName: 'Device Name',
      appVersion: '應用版本',
      activationStatus: 'Activation Status',
      info: '資料',
      editTitle: 'Device Edit',
      appId: 'App ID',
      deviceIp: 'Device IP',
      deviceModel: 'Device Model',
      manufacturer: 'Manufacturer',
      os: 'OS',
      osId: 'OS ID',
      pushNotificationToken: 'Push Notification Token',
    },
    DeviceLog: {
      mainTitle: 'Device Log',
      uploadTimestamp: 'Upload Timestamp',
      appVersion: 'App Version',
      osVersion: 'OS Version',
      status: '狀態',
      fileUrl: 'File URL',
    },
    QueueProfile: {
      mainTitle: 'Queue Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Queue Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: '狀態',
      editTitle: 'Queue Profile Edit',
    },

    QueueTransferProfile: {
      mainTitle: 'Queue Transfer Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Queue Transfer Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: '狀態',
      editTitle: 'Queue Transfer Profile Edit',
    },

    SoundTemplate: {
      mainTitle: 'Sound Template',
      soundTemplateCode: 'Sound Template Code',
      name: 'Name',
      createTitle: 'Sound Template Create',
      editTitle: 'Sound Template Edit',
      status: '狀態',
      restUrlId: 'Rest Url Id',
      code: 'Code',
    },

    Counter: {
      mainTitle: 'Counter',
      createTitle: 'Counter Create',
      editTitle: 'Counter Edit',
      name: '名稱',
      soundTemplateCode: 'Sound Template Code',
      status: '狀態',
      soundTemplateCodeRestUrlId: 'Sound Template Code Rest Url Id',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
    },

    CounterProfile: {
      mainTitle: 'Counter Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Counter Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: '狀態',
      editTitle: 'Counter Profile Edit',
      refCounterList: 'Ref Counter List',
      counterSequence: 'Counter Sequence',
      selectCounter: 'Select Counter',
      addCounter: 'Add Counter',
    },

    MerchantUser: {
      mainTitle: '商戶用戶',
      userRole: '用戶角色',
      username: '用戶名',
      password: '密碼',
      newPassword: '新密碼',
      email: '電子郵件',
      channel: '頻道',
      enabled: '啟用',
      enableMultiLogin: '啟用多重登錄',
      restUrlIdList: 'RestUrlId 列表',
      permission: '權限',
      reportPermission: '報告',
      deviceManagementPermission: '設備管理',
      featurePermission: '功能',
      restaurantManagementPermission: '餐廳管理',
      userGroupManagementPermission: '用戶組管理',
      read: 'Read',
      write: 'Write',
      editTitle: '編輯商家用戶',
      info: '資料',
      createTitle: '新增商家用戶',
      emailError: '請輸入正確的電子郵件格式！',
      usernameUpperCaseError: '不支持大寫字母！',
      usernameLengthError: '用戶名長度應小於45個字母！',
      privilegeAttribute: 'Privilege Attribute',
      privilegeRestUrlId: 'Privilege Rest Url Id',
      privilegeGroupCode: 'Privilege Group Code',
      merchantRole: 'Merchant Role',
      merchantUserList: 'Merchant User List',
      userGroupCode: 'User Group Code',
      changeUsername: '更改用戶名稱',
    },

    MerchantSalesUser: {
      mainTitle: '商戶銷售用戶',
      username: '用戶名',
      password: '密碼',
      info: '資料',
      createTitle: '新增商家銷售用户',
      usernameUpperCaseError: '不支持大寫字母！',
      usernameLengthError: '用戶名長度應小於45個字母！',
    },

    MerchantDraft: {
      mainTitle: 'Merchant Create',
      //merchantDraftList
      draftName: '草稿名稱',
      status: '狀態',
      draftVersion: '草稿版本',
      name: '名稱',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      tcAddress: '地址(繁體中文)',
      enAddress: '地址(英文)',
      scAddress: '地址(簡體中文)',
      addressCustomize: '地址（自動將繁體翻譯成簡體）',
      phoneAddress: '電話及地址',
      phone: '電話',
      shopLogo: 'Logo Image',
      shopFrontImage: 'Shop Front Image',
      shopFrontImageList: 'Shop Front Image List',
      br: 'BR',
      planCode: 'Plan Code',
      servicePlan: '服務計劃',
      shopInfo: '店鋪信息',
      image: '圖像',
      uploadImage: '上傳圖片',
      enterDraftName: '輸入草稿名稱',
    },

    Area: {
      mainTitle: '區域',
      areaId: '區域Id',
      name: '區域名稱',
      enName: '區域名稱(英文)',
      tcName: '區域名稱(繁體中文)',
      scName: '區域名稱(簡體中文)',
      status: '區域狀態',
      editTitle: '區域編輯',
      info: '資料',
      districtList: '地區列表',
      landmarkOrder: '訂單順序',
      createTitle: '區域新增',
    },

    RestaurantDistrict: {
      mainTitle: '地區',
      hk: '香港島 (1)',
      kln: '九龍 (2)',
      nt: '新界 (3)',
      island: '離島 (4)',
      area: '區域',
      areaId: '區域ID',
      name: '地區名稱',
      status: '地區狀態',
      districtStatus: '地區狀態',
      districtId: '地區ID',
      enName: '地區名稱(英文)',
      tcName: '地區名稱(繁體中文)',
      scName: '地區名稱(簡體中文)',
      districtTcName: '地區名稱(繁體中文)',
      districtEnName: '地區名稱(英文)',
      districtScName: '地區名稱(簡體中文)',
      areaTcName: '區域名稱(繁體中文)',
      areaEnName: '區域名稱(英文)',
      areaScName: '區域名稱(簡體中文)',
      addDistrictMap: '添加地區',
      //RestaurantDistrictList
      RestaurantDistrictListTitle: '地區列表：',
      addRestaurantDistrictTitle: '添加地區： ',
      district_name: '地區名稱',
      //RestaurantDistrict Edit
      editTitle: '地區編輯',
      info: '資料',
      restaurantMapList: '餐廳列表',
      landMarkMapList: '地標列表',
      districtLevel: '地區級別',
      menuChiOrder: '菜單順序',
      latitude: '緯度',
      longitude: '經度',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      createTitle: '地區新增',
    },

    //RestaurantDistrictRestMap
    RestaurantDistrictRestMap: {
      mainTitle: '餐廳列表',
      restUrlId: 'Rest Url ID',
      restRefId: 'Ref ID',
      name: '餐廳名稱',
      restTcName: '餐廳名稱(繁體中文)',
      restEnName: '餐廳名稱(英文)',
      restScName: '餐廳名稱(簡體中文)',
      address: '地址',
      restStatus: '餐廳狀態',
    },

    //RestaurantLandmark
    RestaurantLandmark: {
      mainTitle: '地標列表',
      landmarkId: '地標Id',
      tcName: '地標名稱(繁體中文)',
      enName: '地標名稱(英文)',
      scName: '地標名稱(簡體中文)',
      landmarkTcName: '地標名稱(繁體中文)',
      landmarkEnName: '地標名稱(英文)',
      landmarkScName: '地標名稱(簡體中文)',
      name: '地標名稱',
      status: '地標狀態',
      districtList: '地區列表',
      restaurantList: '餐廳列表',
      editTitle: '地標編輯',
      landmarkOrder: '地標順序',
      favorite: '最喜歡的',
      createTitle: '地標新增',
      info: '資料',
    },

    //RestaurantDistrictMap
    RestaurantDistrictMap: {
      mainTitle: 'District Map List',
      districtId: '地區ID',
      enName: '地區名稱(英文)',
      tcName: '地區名稱(繁體中文)',
      scName: '地區名稱(簡體中文)',
      status: '狀態',
      districtstatus: '地區狀態',
      addDistrictMap: '添加地區',
      //RestaurantDistrictMap Edit
      editTitle: '地區編輯',
      info: '資料',
      createTitle: '地區新增',
      addRestaurantMapList: '添加餐廳',
    },

    //UploadImage
    UploadImage: {
      mainTitle: '上傳圖片',
      imageAdd: '新增圖片',
      image: '圖片',
      recordId: 'Record ID',
      tablenName: 'Table Name',
      fieldName: 'Field Name',
      pressUploadImage: '按這裏上傳圖片',
      relatedPictures: '相關圖片',
      dropFile: '按此上載圖片',
      Adv: 'Advance',
    },

    //push
    Push: {
      mainTitle: 'Push',
      name: '名稱',
      messageTitle: '信息標題',
      message: '信息',
      messageTitleCustomize: '信息標題（自動將繁體翻譯成簡體）',
      messageCustomize: '信息（自動將繁體翻譯成簡體）',
      tcMessageTitle: '信息標題(繁體中文)',
      enMessageTitle: '信息標題(英文)',
      scMessageTitle: '信息標題(簡體中文)',
      enMessage: '信息(英文)',
      tcMessage: '信息(繁體中文)',
      scMessage: '信息(簡體中文)',
      type: '種類',
      actionType: 'Action Type',
      startDeliveryDate: '開始發送日期',
      endDeliveryDate: '結束發送日期',
      deliveryDate: '發送日期',
      deliveryDateWarning: '由於發送日期早於今日日期，發送將設置為現在。 你還確認發布嗎？',
      messageGen: '訊息加載狀態',
      sendWarning: 'Should set one of the field as true!',
      notification: '發送到通知',
      inbox: '發送到收件箱',
      enterMessageContent: 'Behaviour',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      filterParameters: 'Filter Parameters',
      messageContent: 'Behaviour',
      enterParameter: 'Parameter',
      query: 'Query',
      gender: '性别',
      female: '女',
      male: '男',
      all: '全部',
      os: 'OS',
      iOS: 'iOS',
      Android: 'Android',
      GMS: 'GMS (Google)',
      HMS: 'HMS (華為)',
      ageGroupList: '年齡',
      addMobile: '添加手機號碼',
      mobileList: '手機號碼',
      enterservice: 'Type',
      ticket: '取票',
      takeaway: '外賣',
      reservation: '預約',
      social_media: '社交媒體',
      start_date: '開始日期',
      end_date: '結束日期',
      consume: '消費',
      times: '消費次數',
      Header: '資訊',
      parameterHeader: 'Condition',
      parameter: 'Parameter',
      promotion: '推廣',
      webView: '網頁瀏覽',
      deepLink: '連結',
      AdsCode: 'Ads Code',
      website: '網站',
      restaurant: '餐廳',
      search: '搜索頁',
      brandCode: 'Brand Code',
      folderEngName: 'Folder名稱(英文)',
      folderTCName: 'Folder名稱(繁體中文)',
      folder: 'Folder',
      folderName: 'Folder名稱',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      categoryCode: 'Category Code',
      productCat: '產品分類',
      productCatName: '產品分類名稱',
      productCatEngName: '產品分類名稱(英文)',
      productCatTCName: '產品分類名稱(繁體中文)',
      productCatCode: '產品分類Code',
      productDetail: '產品詳情',
      productDetailName: '產品詳情名稱',
      productDetailEngName: '產品詳情名稱(英文)',
      productDetailTCName: '產品詳情名稱(繁體中文)',
      productCode: '產品詳情Code',
      ECoupon: 'ECoupon',
      ECouponTitle: 'ECoupon 標題',
      ECouponEngTitle: 'ECoupon標題(英文)',
      ECouponTCTitle: 'ECoupon標題(繁體中文)',
      ECouponCode: 'ECoupon Code',
      from: 'From',
      action: 'Action',
      systemMessage: '系統訊息',
      status: '狀態',
      draft: '草稿',
      testing: '測試',
      scheduled: '預定',
      sending: '發送中',
      finished: '完成',
      sql: 'SQL',
      sqlButton: 'Preview',
      Adv: 'Advance',
      total: 'Total',
      title: 'Title',
      editTitle: 'Push編輯',
      ClearCat: '清除產品分類',
      ClearDetail: '清除產品詳情',
      createTitle: 'Push新增',
      info: '資料',
      productParent: '选择品牌/类别',
      eCouponParent: '选择E-COUPON品牌/类别',
      ecouponCode: 'E-Coupon ',
    },

    //Select Tag
    SelectTag: {
      mainTitle: 'Select Tag',
      name: '名稱',
      tag_code: 'Tag Code',
      select_tag_name: 'Select Tag 名稱',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      messageTitle: '信息標題',
      message: '信息',
      messageTitleCustomize: '信息標題（自動將繁體翻譯成簡體）',
      messageCustomize: '信息（自動將繁體翻譯成簡體）',
      tcMessageTitle: '信息標題(繁體中文)',
      enMessageTitle: '信息標題(英文)',
      scMessageTitle: '信息標題(簡體中文)',
      enMessage: '信息(英文)',
      tcMessage: '信息(繁體中文)',
      scMessage: '信息(簡體中文)',
      type: '種類',
      editTitle: '編輯Select Tag',
      info: '資料',
      createTitle: '新增Select Tag',
      tag_name: '名稱',
      tagCode: 'Tag Code',
      addSelectTag: '添加 Select Tag',
      status: '狀態',
    },

    SelectTagConfig: {
      mainTitle: 'Select Tag 配置',
      tag_name: '名稱',
      tagEnName: '名稱(英文)',
      tagTcName: '名稱(繁體中文)',
      tagScName: '名稱(簡體中文)',
      select_tag_name: 'Select Tag 名稱',
      tagCode: 'Tag Code',
      status: '狀態',
      tag_code: 'Tag Code',
      sequence: '序列',
      type: '類型',
      info: '資料',
      restUrlId: 'Rest Url ID',
      id: 'ID',
      createTitle: '新增Select Tag',
      editTitle: '編輯Select Tag',
      addSelectTag: '添加 select Tag',
    },

    //LandingSection
    LandingSection: {
      mainTitle: '編輯Landing Section',
      editTitle: '編輯Landing Section',
      createTitle: 'Landing Section Create',
      title: '標題',
      info: '資料',
      serviceType: '服務類型',
      mainSection: 'Main Section',
      sectionType: 'Section Type',
      tcTitle: '標題(繁體中文)',
      enTitle: '標題(英文)',
      scTitle: '標題(簡體中文)',
      titleColor: '標題顏色',
      startDisplayTimestamp: '開始顯示時間',
      endDisplayTimestamp: '結束顯示時間',
      sequence: '序列',
      status: '狀態',
      action: 'Action Type',
      displayActionType: 'Action Type',
      actionParameters: 'Action Parameters',
      itemType: 'Item Type',
      tcSubTitle: '副標題(繁體中文)',
      enSubTitle: '副標題(英文)',
      scSubTitle: '副標題(簡體中文)',
      serviceTypeList: '服務類型列表',
      foregroundColor: '前景色',
      backgroundColor: '背景色',
      addItem: '新增Item',
      addSection: '新增Section',
      url: 'URL',
      addParameter: '添加Parameter',
      type: '類型',
      query: 'Query',
      name: '名稱',
      folderType: 'Folder Type',
      brandCode: '品牌代碼',
      categoryCode: '類別代碼',
      ecouponCode: 'Ecoupon代碼',
      couponCode: 'Coupon代碼',
      folderCode: 'Folder代碼',
      //showCategoryList: "显示类别列表",
      productCode: '產品代碼',
      restUrlId: 'restUrl Id',
      from: 'From',
      showCategoryBrandList: 'Show Category/Brand List',
      refId: 'Ref Id',
      subAction: 'Action',
      ticketId: 'Ticket Id',
      infoId: 'Info Id',
      groupCode: 'Group Code',
      code: 'Code',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      nameCustomizeSubTitle: '名稱（自動將繁體翻譯成簡體）',
      tcImage: '圖像(繁體中文)',
      enImage: '圖像(英文)',
      scImage: '圖像(簡體中文)',
      channel: 'Channel',
      sequenceAfter: '序列 (插入於其後)',
      more: '更多',
      freeCollect: '免費拎取',
      youtubeCode: 'Youtube Code',
      tagCode: 'Tag Code',
      copyDataFrom: '複製資料',
      publishing: 'View Only Publishing Item',
    },

    brandApprove: {
      mainTitle: 'Brand Approve',
      restUrlId: 'Rest Url ID',
      name: '品牌名稱',
      enName: '品牌名稱(英文)',
      tcName: '品牌名稱(繁體中文)',
      scName: '品牌名稱(簡體中文)',
      enDescription: '詳細資料(英文)',
      tcDescription: '詳細資料(繁體中文)',
      scDescription: '詳細資料(簡體中文)',
      logoImage: 'Logo Image',
      imageList: '圖像列表',
      status: '狀態',
      rejectReason: '拒絕原因',
      rejectReasonTip: '(如果要按下拒絕按鈕，請先填寫拒絕原因!)',
      editTitle: 'Brand Approve Edit',
      createTitle: 'Brand Approve Create',
      info: '資料',
    },

    restaurantApprove: {
      mainTitle: '餐廳',
      channel: '頻道',
      restUrlId: 'Rest Url ID',
      email: '電子郵件',
      salesName: '銷售員名稱',
      salesCreateTimestamp: '銷售員創建時間',
      approvalStatus: '批准狀態',
      approvalDate: '上一次批准時間',
      name: '餐廳名稱',
      address: '地址',
      uploadedBankInfo: '銀行信息已上傳',
      uploadedBr: 'Br圖片已上傳',
      imageUrl: 'imageUrl',
      refID: 'Ref ID',
      uploadedContractSignature: '上傳合同簽名',
      updateTimestamp: '更新時間',
      //restaurantApproveEdit
      restaurantInfo: '餐廳資料',
      salesInfo: '銷售信息',
      bank: '銀行',
      bankAccount: '銀行帳戶',
      bankAccountName: '銀行帳戶名',
      bankCode: '銀行代碼',
      bankContact: '銀行聯絡電話',
      bankContactPerson: '銀行聯繫人',
      bankName: '銀行名稱',
      image: '文檔媒體圖像（按圖縮放）',
      otherDocument: '其他文件 (按圖縮放)',
      contact: '聯絡電話',
      contactPerson: '聯繫人',
      jobTitle: '職稱',
      enName: '餐廳名稱(英文)',
      tcName: '餐廳名稱(繁體中文)',
      scName: '餐廳名稱(簡體中文)',
      tcAddress: '地址(繁體中文)',
      enAddress: '地址(英文)',
      scAddress: '地址(簡體中文)',
      rejectReason: '拒絕原因',
      rejectReasonTip: '(如果要按下拒絕按鈕，請先填寫拒絕原因!)',
      merchantInfo: '商戶信息',
      editTitle: 'Restaurant Approve Edit',
      createTitle: 'Restaurant Approve Create',
      info: '資料',
    },

    payment: {
      mainTitle: '付款',
      channel: '頻道',
      restUrlId: 'Rest Url ID',
      email: '電子郵件',
      tranRef: 'TranRef',
      tranDate: '交易日期',
      planCode: 'Plan Code',
      paymentCode: '付款代碼',
      paymentDate: '付款日期',
      status: '交易狀態',
      approvalStatus: '審批狀態',
      cheque: '支票',
      bankAccountNumber: '銀行帳號',
      chequeNumber: '支票號碼',
      name: '餐廳名稱',
      sale: 'SALES',
      self: 'SELF',
      hsbc: 'HSBC',
      //paymentEdit
      editTitle: '編輯產付款',
      order: '訂單',
      plan: '計劃',
      restaurant: '餐廳',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      orderChargePrice: '訂單收費價格',
      bankTcName: '銀行名稱(繁體中文)',
      bankEnName: '銀行名稱(英文)',
      bankScName: '銀行名稱(簡體中文)',
      bankCode: '銀行代碼',
      bankInDate: 'Bank In Date',
      planPeriodType: '計劃週期類型',
      planTargetDate: '計劃目標日期',
      planEnName: '計劃名稱(英文)',
      planTcName: '計劃名稱(繁體中文)',
      planScName: '計劃名稱(簡體中文)',
      planPeriod: '計劃週期',
      expiryDate: '到期日',
      planEcouponPublishLimit: 'Plan Ecoupon Publish Limit',
      contractToPdf: '將合同導出為PDF',
      //PaymentCreate
      createTitle: '新增產付款',
      info: '資料',
      servicePlan: '服務計劃',
      paymentMethod: '付款方式',
      cash: '現金',
      price: '價錢',
      issueChequeBank: '簽發支票銀行',
      chequeImage: '支票圖片',
    },

    uploadPayment: {
      mainTitle: '上傳付款',
      //paymentList
      restTcName: '餐廳名稱',
      restUrlId: 'Rest Url ID',
      tranRef: 'TranRef',
      tranDate: '交易日期',
      chequeNumber: '支票號碼',
      matchStatus: 'Match Status',
      approvalStatus: '批准狀態',
    },

    salesRestaurant: {
      mainTitle: '餐廳',
      //restaurantApproveList
      name: '餐廳名稱',
      tcName: '餐廳名稱',
      logoImage: 'Logo Image',
      shopFrontImage: 'Shop Front Image',
      hkId: 'HKId Card',
      br: 'BR',
      authorisationLetter: 'Authorisation Letter',
      bankInfoSignature: 'Bank Info Signature',
      contractSignature: 'Contract Signature',
      otherDocuments: 'Documents',
      restUrlId: 'Rest Url ID',
      address: '地址',
      tcAddress: '地址',
      salesCreateTimestamp: '銷售員創建時間',
      salesName: '銷售員名稱',
      approvalStatus: '批准狀態',
      paymentStatus: '付款狀態',
      uploadedContractSignature: '上傳合同簽名',
      updateTimestamp: '更新時間',
    },

    salesFrontImage: {
      mainTitle: 'Shop Front Gallery',
      //salesFrontImageList
      name: '餐廳名稱',
    },

    salesRackProduct: {
      mainTitle: '產品',
      //restaurantApproveList
      restUrlId: 'Rest Url ID',
      restName: '餐廳名稱',
      restTcName: '餐廳名稱',
      productCode: '產品代碼',
      productName: '產品名稱',
      productTcName: '產品名稱',
      salesCreateTimestamp: '銷售員創建時間',
      salesName: '銷售員名稱',
      startDisplayTimestamp: '開始顯示時間',
      endDisplayTimestamp: '結束顯示時間',
      published: '已發布',
      updateTimestamp: '更新時間',
    },

    restaurantApprovalSalesRackProduct: {
      mainTitle: '產品',
      //restaurantApproveList
      restUrlId: 'Rest Url ID',
      restTcName: '餐廳名稱',
      restName: '餐廳名稱',
      productCode: '產品代碼',
      productName: '產品名稱',
      productTcName: '產品名稱',
      salesCreateTimestamp: '銷售員創建時間',
      salesName: '銷售員名稱',
      startDisplayTimestamp: '開始顯示時間',
      endDisplayTimestamp: '結束顯示時間',
      status: '狀態',
      updateTimestamp: 'Update Time',
    },

    productCreateDraft: {
      mainTitle: '產品草稿列表',
      //productCreateDraftList
      listName: '產品草稿',
      draftName: '草稿名稱',
      status: '狀態',
      draftVersion: '草稿版本',
    },

    productCreateList: {
      mainTitle: '產品創建列表',
      //productCreateList
      userGroup: '用戶組',
      productCat: '產品類別',
      redeemGroup: '產品兌換',
      productDetail: '產品詳情',
      lotInventory: '批次庫存',
      enterDraftName: '輸入草稿名稱',
    },

    productCreateUserGroupList: {
      mainTitle: '產品用戶組',
      //ProductCreateUserGroupList
      listName: '產品用戶組列表',
      groupCode: '用戶組代碼',
      groupDesc: '用戶組描述',
      clearUserGroup: '清除用戶組',
    },

    productCreateProductCategoryList: {
      mainTitle: '產品類別',
      menuName: '類別',
      channel: '頻道',
      categoryType: '類別類型',
      typeList: '類別類型',
      categoryChannel: '頻道',
      displaySequence: '顯示順序',
      codeDescription: '類別代碼說明',
      categoryCodeDescription: '類別代碼說明',
      status: '狀態',
      categoryCode: '類別代碼',
      name: '類別名稱',
      tcName: '類別名稱(繁體中文)',
      enName: '類別名稱(英文)',
      scName: '類別名稱(簡體中文)',
      enDescription: '描述(英文)',
      tcDescription: '描述(繁體中文)',
      scDescription: '描述(簡體中文)',
      //ProductCreateProductCategoryList
      createnName: '新增產品類別',
      refCategoryCode: '參考類別代碼',
      handleClearRefCategoryCode: '清除參考類別代碼',
      startDisplayTimestamp: '開始顯示時間',
      endDisplayTimestamp: '結束顯示時間',
      imageList: '圖像列表',
      uploadImage: '上傳圖片',
      type: '類型',
      autoDisplaySequence: '自動更新顯示順序',
      categoryInfoNotFind: '草稿版本不正確，因此找不到類別信息！如果需要查看類別信息，請再次按一下列表中的類別！',
      addProduct: '添加產品',
      productMapList: '產品列表',
      productCode: '產品代碼',
      productChannel: '產品頻道',
      productEnName: '產品名稱(英文)',
      productTcName: '產品名稱(繁體中文)',
      productScName: '產品名稱(簡體中文)',
      editName: '產品類別',
      createTitle: '新增產品類別',
      editTitle: '編輯產品類別',
      info: '資料',
    },

    ProductMapList: {
      mainTitle: '產品列表',
      productCode: '產品代碼',
      productType: '產品類型',
      productChannel: '產品頻道',
      productName: 'Product Name',
      productEnName: '產品名稱(英文)',
      productTcName: '產品名稱(繁體中文)',
      productScName: '產品名稱(簡體中文)',
      productStatus: '產品狀態',
      displaySequence: '顯示順序',
      addProductMap: '添加產品',
      status: '狀態',
      editTitle: '編輯產品',
      info: '資料',
      createTitle: '新增產品',
    },

    productCreateRedeemGroupList: {
      mainTitle: '產品兌換',
      redeemGroupCode: 'Redeem Group Code',
      codeDescription: '產品兌換說明',
      status: '狀態',
      //ProductCreateRedeemGroupList
      listName: '產品兌換列表',
      addExistingRedeemGroup: '添加現有兌換',
      title: '產品兌換',
      info: '資料',
      createnName: '新增產品兌換',
      selectLocation: '選擇位置',
      selectDate: '選擇日期',
      selectTime: '選擇時間',
      type: '兌換類型',
      startRedeemTimestamp: '開始兌換時間',
      endRedeemTimestamp: '結束兌換時間',
      startAfterDay: 'Start After Day (*For pre-order use)',
      endAfterDay: 'End After Day',
      redeemLocationList: '兌換位置列表',
      total: '總數',
      addRedeemLocation: '增加兌換位置',
      restUrlId: 'Rest Url Id',
      restEnName: '餐廳名稱(英文)',
      restTcName: '餐廳名稱(繁體中文)',
      restScName: '餐廳名稱(簡體中文)',
      startTime: '開始時間',
      endTime: '結束時間',
      interval: '時間間隔',
      selectAllRedeemLocation: 'Select All Redeem Location',
    },

    productCreateProductList: {
      mainTitle: '產品',
      categoryType: '類別類型',
      channel: '頻道',
      name: '名稱',
      status: '狀態',
      type: '類型',
      productCode: '產品代碼',
      tcName: '產品名稱(繁體中文)',
      enName: '產品名稱(英文)',
      scName: '產品名稱(簡體中文)',
      productChannel: '產品頻道',
      productEnName: '產品名稱(英文)',
      productTcName: '產品名稱(繁體中文)',
      productScName: '產品名稱(簡體中文)',
      productStatus: '產品狀態',
      groupEmails: 'Group Emails',
      merchantProduct: 'Merchant Product',
      //ProductCreateProductpList
      listName: '新增產品列表',
      all: 'All',
      eCoupon: 'ECoupon',
      preOrder: 'PreOrder',
      package: 'Package',
      packageSubItem: 'Package Sub Item',
      freeEcouponItem: 'Free Ecoupon Item',
      publishStatus: '發布狀態',
      addMezzofyProduct: 'Add Mezzofy Product',
      mezzofyCouponID: 'Mezzofy Coupon ID',
      productSystemGroupListTitle: 'Product System Group List',
      createnName: '新增產品',
      product: '產品',
      nameCustomize: '名稱（自動將繁體翻譯成簡體）',
      redeemGroupCode: 'Redeem Group Code',
      withoutRedeemGroupCode: '未有合適既Redeem group',
      brandCode: '品牌',
      systemGroup: 'System Group',
      description: '說明',
      tcDescription: '產品說明(繁體中文)',
      enDescription: '產品說明(英文)',
      scDescription: '產品說明(簡體中文)',
      descriptionCustomize: '產品說明（自動將繁體翻譯成簡體）',
      tcTac: 'Tc Tac',
      enTac: 'En Tac',
      scTac: 'Sc Tac',
      tacCustomize: 'Tac（自動將繁體翻譯成簡體）',
      tcRedeemDescription: '兌換說明(繁體中文)',
      enRedeemDescription: '兌換說明(英文)',
      scRedeemDescription: '兌換說明(簡體中文)',
      redeemDescriptionCustomize: '兌換說明（自動將繁體翻譯成簡體）',
      tcDiscountDescription: '折扣說明(繁體中文)',
      enDiscountDescription: '折扣說明(英文)',
      scDiscountDescription: '折扣說明(簡體中文)',
      discountDescriptionCustomize: '折扣說明（自動將繁體翻譯成簡體）',
      tcTag: 'Tc Tag',
      enTag: 'En Tag',
      scTag: 'Sc Tag',
      display: '顯示',
      startDisplayTimestamp: '開始顯示時間',
      endDisplayTimestamp: '結束顯示時間',
      displaySequence: '顯示順序',
      sellingInfo: '產品銷售信息',
      originalPrice: '原價',
      sellingPrice: '銷售價格',
      personLimit: '人員限制',
      quantityPerOrder: '每個訂單數量',
      dailyQuota: '每日配額',
      bufferRedeemDay: 'Buffer Redeem Day',
      selfRedeem: '自我兌換',
      selfRefund: '自我退款',
      searchable: '可搜索',
      shareable: '可共享',
      packageCashCoupon: 'Package Cash Coupon',
      requireFillInContactPerson: 'Require Fill In Contact Person',
      requireFillInMobileNumber: 'Require Fill In Mobile Number',
      requireFillInEmail: 'Require Fill In Email',
      addSubProductMap: '添加 Sub Product Map',
      subProductMapList: 'Sub Product Map List',
      subProductMap: 'Sub Product Map',
      distributeMinute: '發放分鐘',
      quantity: '數量',
      categoryMapList: 'Category Map List',
      addCategory: '增加類別',
      categoryCode: '類別代碼',
      codeDescription: '類別代碼說明',
      categoryName: '類別名稱',
      categoryEnName: '類別名稱(英文)',
      categoryTcName: '類別名稱(繁體中文)',
      categoryScName: '類別名稱(簡體中文)',
      imageList: '圖像列表',
      uploadImage: '上傳圖片',
      autoDisplaySequence: '自動更新顯示順序',
      settlementConfig: 'Settlement Config',
      addBlackoutPeriod: '添加無法使用時間',
      blackoutPeriodList: '無法使用期間列表',
      restUrlId: 'Rest Url Id',
      startTimestamp: '開始時間',
      endTimestamp: '結束時間',
      attributeList: '屬性列表',
      addAttributeList: '添加屬性',
      key: 'Attribute Key',
      attributeType: '屬性類型',
      criteria: '標準',
      min: '最小值',
      max: '最大值',
      optionList: '選項列表',
      code: 'Option Code',
      addOptionList: '添加選項',
      tcValue: '值(繁體中文)',
      enValue: '值(英文)',
      scValue: '值(簡體中文)',
      valueCustomize: '值（自動將繁體翻譯成簡體）',
      defaultValue: '默認值',
      typeChange: '更改類型',
      typeChangeMessage: '該產品的所有輸入數據將被清除，您要繼續更改類型嗎？',
      editName: '產品',
      info: '資料',
    },

    productCreateSystemGroupList: {
      mainTitle: 'System Group',
      name: '名稱',
      groupEmails: '團體電郵',
      info: '資料',
      editTitle: 'System Group 编辑',
      addEmail: '添加電郵',
      removeEmail: '移除電郵',
      createTitle: 'System Group Create',
      restaurantList: '餐厅列表',
      restUrlId: 'restUrlId',
      enName: '餐廳名稱(英文)',
      tcName: '餐廳名稱(繁體中文)',
      scName: '餐廳名稱(簡體中文)',
      addRestaurant: '添加餐廳',
      restRefId: 'Ref ID',
      address: '地址',
      systemUser: 'System User',
      addRestaurantList: 'Add Restaurant List',
      RestaurantList: 'Restaurant List',
      systemGroupInfo: 'System Group Info',
    },

    systemUser: {
      mainTitle: 'System User',
      editTitle: 'System User Edit',
      username: 'Username',
      userRole: 'User Role',
      restUrlId: 'Rest Url Id',
      privilegeAttribute: 'Privilege Attribute',
      status: '狀態',
      info: '資料',
      newPassword: 'New Password',
      email: 'E-mail',
      apiGroup: 'API Group',
      password: 'Password',
      createTitle: 'System User Create',
      systemGroupId: 'System Group Id',
    },

    productCreateProductLotList: {
      mainTitle: '產品批次庫存',
      //ProductCreateProductLotList
      listName: '產品批次庫存清單列表',
      addLotInventory: '添加批次庫存',
      lotCode: '批次代碼',
      quantity: '數量',
      type: '類型',
      uploadLotFile: '上傳文件',
      mezzofyCouponID: 'Mezzofy Coupon ID',
      mezzofyCouponCode: 'Mezzofy Coupon Code',
      mezzofyCouponQuantitySync: 'Mezzofy Coupon Quantity Sync',
      mezzofySubItemWarning: 'Mezzofy does not include Sub Item Lot Inventory!',
      massCode: 'Mass Code',
      enName: '名稱(英文)',
      tcName: '名稱(繁體中文)',
      scName: '名稱(簡體中文)',
      remainingQuota: '剩餘配額',
      totalRemainingQuota: '總剩餘配額',
    },

    contactUs: {
      mainTitle: '聯繫我們',
      topic: '主題',
      caseCode: '案例代碼',
      contactPerson: '聯繫人',
      contactNumber: '聯繫電話',
      email: '電子郵件',
      refId: '參考編號',
      messageType: '頻道',
      tagList: '標籤',
      tagListDisplay: '標籤',
      previewMessage: '消息預覽',
      appMemberId: '應用程式會員編號',
      appVersion: '應用程式版本',
      osVersion: '系統版本',
      orderId: '訂單編號',
      companyType: '公司類型',
      restaurantName: '餐廳名稱',
      message: '消息',
      mediaList: '媒體',
      remarks: '備註',
      status: '狀態',
      editTitle: '編輯聯繫我們',
      info: '資料',
      createTimestamp: '日期',
      tagInputLabel: '增加標籤',
    },

    imageWarning: {
      mainTitle: 'Image Size:',
      weChat: '4BY3 = IMAGE (480 X 360)',
      icon: 'ICON = SQUARE IMAGE (500 X 500)',
      detail: 'DETAIL = BANNER IMAGE (500 x 500)',
    },

    MerchantUserGroup: {
      mainTitle: 'Merchant User Group',
      groupCode: 'Group Code',
      groupDesc: 'Group Description',
      editTitle: 'Merchant User Group Edit',
      info: '資料',
      restaurantMap: 'Restaurant Map List',
      name: 'name',
      RestaurantList: 'Restaurant List',

      address: 'Address',
      restUrlId: 'restUrlId',
      groupEmails: 'Group Emails',
      addEmail: 'Add Email',
      removeEmail: 'Remove Email',
      createTitle: 'Merchant User Group Create',
      restaurantList: 'Restaurant List',
      enName: 'Restaurant En Name',
      tcName: 'Restaurant Tc Name',
      scName: 'Restaurant Sc Name',
      addRestaurant: 'Add Restaurant',
      systemUser: 'System User',
      addRestaurantList: 'Add Restaurant List',
      systemGroupInfo: 'System Group Info',
      emails: 'Email',
      addSettlementConfigList: 'Add Settlement Config List',
      SettlementConfigList: 'Settlement Config List',

      serviceType: 'Service Type',
      description: 'Description',
      profile: 'Profile',
      alipayHkRate: 'AlipayHk Rate',
      wechatPayRate: 'WechatPay Rate',
      stripeRate: 'Stripe Rate',
      yedRate: 'Yed Rate',
      mpayRate: 'Mpay Rate',
      guluRate: 'Gulu Rate',
      saleSettlementProportion: 'Sale Settlement Proportion',
      redemptionSettlementProportion: 'Redemption Settlement Proportion',
      expiredSettlementRate: 'Expired Settlement Rate',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
    },

    restaurantMap: {
      restRefId: 'Ref ID',
      address: 'Address',
      restUrlId: 'restUrlId',
      name: 'name',
      wholeQrCodeColor: 'Qr Code Color',
      download: 'Download',
    },

    RestaurantPayment: {
      mainTitle: 'Payment',
      serviceType: 'Service Type',
      systemGroupName: 'System Group Name',
      paymentConfigName: 'Payment Config Name',
      paymentCode: 'Payment Code',
      cardType: 'Card Type',
      sequence: '序列',
      status: '狀態',
      editTitle: 'Payment Edit',
      info: '資料',
      restUrlId: 'Rest Url Id',
      createTitle: 'Payment Create',
    },

    RestaurantPaymentConfigList: {
      systemGroupName: 'System Group Name',
      name: '名稱',
      paymentCode: 'Payment Code',
      merchantId: 'merchantId',
      paymentState: 'paymentState',
    },

    RestaurantPaymentTypeList: {
      paymentCode: 'Payment Code',
      cardType: 'Card Type',
      name: '名稱',
    },

    SystemGroupField: {
      systemGroupId: 'System Group Id',
      systemGroupName: 'System Group 名稱',
    },

    password: {
      newPassword: 'New Password',
      enterAgainPassword: 'Enter New Password Again',
    },

    tools: {
      mainTitle: '工具',
      qrCodeGenerator: 'GULU 二維碼生產器',
      qrCodeUrl: '二維碼連結',
      errorCorrectionLevel: 'Error Correction Level',
      cornersSquareOptions: 'Corner Square Options',
      cornersDotOptions: 'Corner dot Options',
      dotsOptions: 'Dots Options',
      width: '闊',
      height: '高',
      image: '圖片',
      wholeQrCodeColor: '二維碼顏色',
      logo: 'Logo',
      download: '下載',
      downloadFormat: '下載格式',
    },

    Staff: {
      mainTitle: '職員',
      username: 'Username',
      id: 'Id',
      editTitle: 'Staff Edit',
      info: 'info',
      staffRole: 'Staff Role',
      refCode: 'Ref Code',
      surname: 'Surname',
      firstName: 'First Name',
      displayName: 'Display Name',
      email: 'Email',
      countryCode: 'Country Code',
      mobile: 'Mobile',
      createTitle: 'Staff Create',
      password: 'Password',
      newPassword: 'New Password',
      queueProfileCode: 'Queue Profile Code',
      queueTransferProfileCode: 'Queue Transfer Profile Code',
      counterProfileCode: 'Counter Profile Code',
      syncNameFieldsWithUsername: 'Sync Name Fields With Username',
      enabled: '顯示已啟用職員',
      resetAccountNameAndPassword: '重設用戶名稱及密碼',
    },

    RestaurantMap: {
      restUrlId: 'Rest Url Id',
      name: '名稱',
      restaurantStatus: 'Restaurant Status',
      mainTitle: 'Restaurant List',
      enName: '名稱(英文)',
      scName: '名稱(簡體中文)',
      tcName: '名稱(繁體中文)',
      createTitle: 'Restaurant Map Create',
      editTitle: 'Restaurant Map Edit',
      landmarkId: 'Landmark Id',
      addRestaurantMapList: 'Add Restaurant Map List',
      status: '狀態',
    },

    Pickup: {
      mainTitle: '提取叫號',
      editTitle: '提取叫號編輯',
      createTitle: '新增提取叫號',
      restUrlId: 'Rest Url Id',
      typeCode: '類型代碼',
      name: '名稱',
      status: '狀態',
      info: '資料',
      enTypeName: '類型名稱(英文)',
      tcTypeName: '類型名稱(繁體中文)',
      scTypeName: '類型名稱(簡體中文)',
      sequence: '序列',
      reverseSequence: '反轉序列',
      foregroundColor: '前景色',
      backgroundColor: '背景色',
      soundTemplateCode: '聲音範本代碼',
      voiceCode: '聲音代碼',
    },
  },
}
