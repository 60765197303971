export const formatTicketBanner = (tempsavedBannerList) => {
  tempsavedBannerList.forEach((banner) => {
    if (banner.configMapList) {
      banner.configMapList = formatConfigMapList(banner.configMapList)
    }
  })

  return tempsavedBannerList
}

export const formatConfigMapList = (configMapList) => {
  const configMap = []

  configMapList.forEach((config) => {
    for (const channel of config.channel) {
      for (const rest of config.restUrlMap) {
        configMap.push({
          channel: channel,
          endDisplayDate: config.endDisplayDate,
          serviceType: config.serviceType,
          startDisplayDate: config.startDisplayDate,
          ticketStatus: config.ticketStatus,
          restUrlId: rest.restUrlId,
        })
      }
    }
  })

  return configMap
}
